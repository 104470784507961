import type { ThunkAction, ThunkDispatch } from 'redux-thunk';
import type { AnyAction } from 'redux';

import { IMAGE, SESSION_STORAGE_REVIEW_KEY, VIDEO } from 'constants/appConstants';
import {
  ADD_REVIEW_FORM_VALIDATION,
  RECEIVE_ADD_REVIEW_MEDIA_UPLOAD,
  RECEIVE_SUBMIT_ADD_REVIEW,
  REQUEST_ADD_REVIEW_MEDIA_UPLOAD,
  REQUEST_SUBMIT_ADD_REVIEW,
  RESET_ADD_REVIEW,
  SET_DOC_META_WRITE_REVIEW,
  UPDATE_ADD_REVIEW_ABOUT,
  UPDATE_ADD_REVIEW_FIT,
  UPDATE_ADD_REVIEW_RATING,
  UPDATE_ADD_REVIEW_SUMMARY
} from 'constants/reduxActions';
import { authenticationErrorCatchHandlerFactory } from 'actions/errors';
import { setSessionCookies } from 'actions/session';
import { submitMedia as submitMediaApi, submitReview as submitReviewApi } from 'apis/cloudreviews';
import { saveToSessionStorage } from 'helpers/ClientUtils';
import { fetchApiNotAuthenticatedMiddleware, fetchErrorMiddleware, fetchErrorMiddlewareAllowedErrors } from 'middleware/fetchErrorMiddleware';
import { processHeadersMiddleware } from 'middleware/processHeadersMiddlewareFactory';
import { getMafiaAndCredentials } from 'store/ducks/readFromStore';
import type { CCFetchOpts } from 'actions/productDetail';
import { fetchProductDetail } from 'actions/productDetail';
import type { FormattedProductBundle } from 'reducers/detail/productDetail';
import type { ApiConfig, AppState } from 'types/app';
import type { Cookies } from 'types/cookies';
import type { ProductLookupKey } from 'types/product';
import type { CloudReviewParams, ReviewData, ReviewMedia } from 'types/cloudReviews';
import { selectMafiaConfig } from 'selectors/environment';

export function setWriteReviewDocMeta(product: FormattedProductBundle, mediaOnly: boolean) {
  return { type: SET_DOC_META_WRITE_REVIEW, product, mediaOnly } as const;
}

type ReviewPageOpts = CCFetchOpts & { mediaOnly: boolean };

export function loadWriteReviewPage(
  lookupKeyObj: ProductLookupKey | string | number,
  options?: ReviewPageOpts
): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return function (dispatch, getState) {
    return dispatch(fetchProductDetail(lookupKeyObj, options)).then(() => {
      const {
        product: { detail }
      } = getState();
      detail && dispatch(setWriteReviewDocMeta(detail, Boolean(options && options.mediaOnly)));
    });
  };
}

export function updateSummary(summary: string) {
  return {
    type: UPDATE_ADD_REVIEW_SUMMARY,
    summary
  } as const;
}

interface ReviewOpts {
  name: string;
  value: string;
}

export function updateRating({ name, value }: ReviewOpts) {
  return {
    type: UPDATE_ADD_REVIEW_RATING,
    rating: { name, value }
  } as const;
}

export function updateFit({ name, value }: ReviewOpts) {
  return {
    type: UPDATE_ADD_REVIEW_FIT,
    fit: { name, value }
  } as const;
}

export function updateAbout({ name, value }: ReviewOpts) {
  return {
    type: UPDATE_ADD_REVIEW_ABOUT,
    about: { name, value }
  } as const;
}

export function resetAddReview() {
  return {
    type: RESET_ADD_REVIEW
  } as const;
}

export const requestAddReview = () => ({ type: REQUEST_SUBMIT_ADD_REVIEW }) as const;

export const requestMediaUpload = () => ({ type: REQUEST_ADD_REVIEW_MEDIA_UPLOAD }) as const;

interface ReceiveAddReviewActionType {
  type: typeof RECEIVE_SUBMIT_ADD_REVIEW;
  reviewId?: string;
}

export const receiveAddReview = (response: { reviewId: string }) => {
  const ret: ReceiveAddReviewActionType = { type: RECEIVE_SUBMIT_ADD_REVIEW };
  // do not add message directly to object. not all messages are safe to show
  // to customers.
  if (response && response.reviewId) {
    ret.reviewId = response.reviewId;
  }
  return ret;
};

export const receiveMediaUpload = () => ({ type: RECEIVE_ADD_REVIEW_MEDIA_UPLOAD }) as const;

// this is just a helper for `addReview`
const makeAddReviewErrorHandler = (args: AddTextReviewOpts) => (e: Error) => {
  const { dispatch, reviewData, returnTo } = args;
  saveToSessionStorage(SESSION_STORAGE_REVIEW_KEY, JSON.stringify(reviewData));
  authenticationErrorCatchHandlerFactory(dispatch, returnTo)(e);
};

// this is just a helper for `addReview`
const makeAddTextReview = (args: AddTextReviewOpts) => (payload: CloudReviewParams) => {
  const { addReviewErrorHandler, credentials, dispatch, getState, submitReviewApi } = args;
  const mafiaConfig = selectMafiaConfig(getState());
  return submitReviewApi(mafiaConfig.url, credentials, payload)
    .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
    .then(fetchApiNotAuthenticatedMiddleware)
    .then(fetchErrorMiddlewareAllowedErrors([400]))
    .then(data => dispatch(receiveAddReview(data)))
    .catch(addReviewErrorHandler);
};

interface UploadPayload {
  productId: string;
  images: string[] | undefined;
  video?: string;
}
// this is just a helper for `addReview`
const uploadMediaAndAddReview = (
  args: AddTextReviewOpts & {
    addTextReview: (payload: CloudReviewParams) => Promise<void | ReceiveAddReviewActionType>;
  }
) => {
  const {
    addReviewErrorHandler,
    addTextReview,
    reviewSubmit: { url },
    credentials,
    dispatch,
    reviewData,
    reviewMedia,
    submitMediaApi
  } = args;

  dispatch(requestMediaUpload());

  const { images, video } = reviewMedia || {};
  const { productId } = reviewData;
  const payload: UploadPayload = { productId, images };

  if (video) {
    payload.video = video;
  }

  return submitMediaApi(url, credentials, payload)
    .then(fetchApiNotAuthenticatedMiddleware)
    .then(fetchErrorMiddleware)
    .then(resp => {
      dispatch(receiveMediaUpload());
      const images: string[] = [];
      let video = null;
      resp.forEach(({ hashValue, mediaType }) => {
        if (mediaType === IMAGE) {
          images.push(hashValue);
        } else if (mediaType === VIDEO) {
          video = hashValue;
        }
      });
      const addTextReviewPayload = { ...reviewData, images, video };
      return addTextReview(addTextReviewPayload);
    })
    .catch(addReviewErrorHandler);
};

interface AddReviewArgs {
  reviewData: ReviewData;
  reviewMedia?: Partial<ReviewMedia>;
  returnTo?: string;
  submitMedia?: typeof submitMediaApi;
  submitReview?: typeof submitReviewApi;
}

interface AddTextReviewOpts {
  credentials: Cookies;
  submitMediaApi: typeof submitMediaApi;
  submitReviewApi: typeof submitReviewApi;
  reviewSubmit: ApiConfig;
  legacyReviewSubmit: ApiConfig;
  dispatch: ThunkDispatch<AppState, void, AnyAction>;
  getState: () => AppState;
  mafia: {
    // TODO ts type this as an "api" entry of state.environmentconfig.api
    url: string;
    siteId: number;
    subsiteId: number;
  };
  returnTo?: string;
  reviewData: ReviewData;
  reviewMedia?: Partial<ReviewMedia>;
  addReviewErrorHandler?: (e: Error) => void;
}

export function addReview({
  reviewData,
  reviewMedia = {},
  returnTo,
  submitMedia = submitMediaApi,
  submitReview = submitReviewApi
}: AddReviewArgs): ThunkAction<Promise<void | ReceiveAddReviewActionType>, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    dispatch(requestAddReview());

    const { images = [], video } = reviewMedia;
    const hasMediaAttached = images.some(Boolean) || video;

    const state = getState();
    const {
      environmentConfig: {
        api: { reviewSubmit, legacyReviewSubmit }
      }
    } = state;
    const mafiaConfig = selectMafiaConfig(state);
    const subroutineArgs: AddTextReviewOpts = {
      credentials: getMafiaAndCredentials(state),
      submitMediaApi: submitMedia,
      submitReviewApi: submitReview,
      reviewSubmit,
      legacyReviewSubmit,
      dispatch,
      getState,
      mafia: mafiaConfig,
      returnTo,
      reviewData,
      reviewMedia
    };
    subroutineArgs.addReviewErrorHandler = makeAddReviewErrorHandler(subroutineArgs);
    const addTextReview = makeAddTextReview(subroutineArgs);

    if (hasMediaAttached) {
      return uploadMediaAndAddReview({ ...subroutineArgs, addTextReview });
    }
    return addTextReview({ ...reviewData });
  };
}

export function addFieldValidationErrors(errorMap: Record<string, boolean>) {
  return {
    type: ADD_REVIEW_FORM_VALIDATION,
    errorMap
  } as const;
}

export type AddReviewAction =
  | ReturnType<typeof setWriteReviewDocMeta>
  | ReturnType<typeof updateSummary>
  | ReturnType<typeof updateRating>
  | ReturnType<typeof updateFit>
  | ReturnType<typeof updateAbout>
  | ReturnType<typeof resetAddReview>
  | ReturnType<typeof requestMediaUpload>
  | ReturnType<typeof receiveMediaUpload>
  | ReturnType<typeof requestAddReview>
  | ReturnType<typeof addFieldValidationErrors>
  | ReceiveAddReviewActionType;
