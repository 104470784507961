import { useEffect } from 'react';

import { sha256 } from 'helpers/ads';

export const RoktAds = ({ email, firstName, geoCountry, orderId }) => {
  useEffect(() => {
    // initialize 3rd party ads
    (function (r, o, k, t, n, e, w, a, _) {
      r._ROKT_ = n;
      r[n] = r[n] || {
        id: t,
        h: w,
        lc: [],
        it: new Date(),
        onLoaded: function (c) {
          r[n].lc.push(c);
        }
      };
      a = o.createElement('script');
      a.type = 'text/javascript';
      a.async = !0;
      a.src = k;
      if (e) {
        a.integrity = e;
        a.crossOrigin = 'anonymous';
      }
      _ = o.getElementsByTagName('script')[0];
      _.parentNode.insertBefore(a, _);
    })(window, document, 'https://wsdk.rokt.com/integrations/snippet.js', '2755887695733739892_f54ee998e45c4070b76f395394aa0aa6', 'rokt');

    const emailsha256 = sha256(email.toLowerCase());
    window.rokt.onLoaded(rokt => {
      rokt.setAttributes({
        // Recommended
        emailsha256,

        // Transaction
        confirmationref: orderId,

        // Consumer
        firstname: firstName,
        gender: '',

        // Address
        zipcode: '',
        city: '',
        state: '',
        country: '',
        language: geoCountry
      });

      // attributes in this section will only be captured on opt-in
      rokt.setFulfillmentAttributes({
        email,
        lastname: ''
      });
    });

    return function cleanup() {
      window.rokt &&
        window.rokt.onLoaded(rokt => {
          rokt.closeAll();
        });
    };
  }, [email, firstName, geoCountry, orderId]);

  return null;
};

export default RoktAds;
