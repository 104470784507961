import queryString from 'query-string';
import type { Location } from 'history';
import type { Store } from 'redux';

import AppEnvironment from 'helpers/AppEnvironment';
import logger from 'middleware/logger';

export function extractEmailAuthToken(router: { location?: Location }) {
  const { search = '' } = (router && router.location) || {};
  const { auth_token: emailAuthToken } = queryString.parse(search) || {};
  return emailAuthToken;
}

/**
 * Returns whether there is an email auth token present.
 *
 * @param {*} param0
 */
export function isEmailAuthTokenPresent({ getState }: Store): boolean {
  const { router } = getState();
  return !!extractEmailAuthToken(router);
}

/**
 * Returns whether the current user has the possibility of being authenticated.  This means that the user has both a x-main and at-main cookie.
 *
 * However there is still the possiblity that the user will be prompted to re-authenticate for a given service.
 * Do not use this on the client as it'll always say you're not authenticated.
 * @param {object} store
 */
export function isMaybeAuthenticated({ getState }: Store): boolean {
  const { cookies } = getState();
  if (AppEnvironment.isClient) {
    /** @todo - Remove this `any` when the Logger module gets typed */
    (logger as any).error('isMaybeAuthenticated should not be used client side.');
  }
  return !!(cookies['x-main'] && cookies['at-main']) || isEmailAuthTokenPresent({ getState } as Store);
}

/**
 * Returns whether the current user is recognized or not. Safe for both server and client use.
 *
 * @param {object} storeOrCookies
 */
export function isRecognized({ getState, 'x-main': xMain }: Partial<Store & { 'x-main': any }>): boolean {
  if (xMain) {
    return true;
  }

  if (getState) {
    const { cookies } = getState();
    return !!cookies['x-main'];
  }

  return false;
}
