import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { cn } from 'helpers/classnames';
import { fetchAccountInfo } from 'actions/account/account';
import { titaniteView } from 'apis/amethyst';
import { setHeaderFooterVisibility } from 'actions/headerfooter';
import { createPurchaseMicrosoftUetEvent, pushMicrosoftUetEvent } from 'actions/microsoftUetTag';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import { trackEcommOrderConfirmation } from 'helpers/contentSquare/analytics';
import { setLastPageWasOrderconfirmation } from 'store/ducks/history/actions';
import { onFetchOrdersByPurchaseId, onReceiveOrdersByPurchaseId } from 'store/ducks/checkout/actions';
import { firePixelServer } from 'actions/pixelServer';
import CartItem from 'components/cart/CartItem';
import MultiLineAddress from 'components/checkout/address/MultiLineAddress';
import ContactUs from 'components/checkout/typ/ContactUs';
import RoktAds from 'components/checkout/typ/RoktAds';
import VoiceOfCustomer from 'components/checkout/typ/VoiceOfCustomer';
import { Loader } from 'components/Loader';
import SiteAwareMetadata from 'components/SiteAwareMetadata';
import { pluralize, stripSpecialCharsDashReplace } from 'helpers';
import { isDesktop } from 'helpers/ClientUtils';
import { toUSD } from 'helpers/NumberFormats';
import { onEvent, supportsPassiveEventListener } from 'helpers/EventHelpers';
import { filter } from 'helpers/lodashReplacement';
import { toFormatted } from 'store/ducks/address/utils';
import { fetchSymphonyContentByPageLayout, resetLandingPageInfo } from 'actions/landing/landingPageInfo';
import LandingSlot from 'containers/LandingSlot.jsx';
import { triggerAssignment } from 'actions/ab';
import { inIframe } from 'helpers/InIframe';

import css from 'styles/containers/checkout/orderConfirmation.scss';
// eslint-disable-next-line css-modules/no-unused-class
import cartCSS from 'styles/containers/cart.scss';

export class OrderConfirmation extends Component {
  state = { isShowingRecosModal: false };

  componentDidMount() {
    const {
      fetchAccountInfo,
      onFetchOrdersByPurchaseId,
      match: {
        params: { purchaseId }
      },
      resetLandingPageInfo,
      setLastPageWasOrderconfirmation,
      fetchSymphonyContentByPageLayout,
      trackEvent,
      killswitch: { showOrderConfirmationRoktAds }
    } = this.props;

    const {
      marketplace: {
        orderConfirmation: { allowRoktAds, symphonyPageLayout, symphonyPageName }
      }
    } = this.context;
    const isInFrame = inIframe();
    if (!isInFrame && allowRoktAds && showOrderConfirmationRoktAds) {
      fetchAccountInfo();
    }

    titaniteView();

    resetLandingPageInfo();

    if (symphonyPageName && symphonyPageLayout) {
      fetchSymphonyContentByPageLayout({
        pageLayout: symphonyPageLayout,
        pageName: symphonyPageName
      });
    }

    const opts = supportsPassiveEventListener() ? { passive: true } : false;
    onEvent(window, 'resize', this.onResize, opts, this);
    this.onResize();

    setLastPageWasOrderconfirmation(true);
    trackEvent('TE_PV_ORDERCONFIRMATION');
    onFetchOrdersByPurchaseId(purchaseId);
  }

  componentDidUpdate(prevProps) {
    const {
      checkoutData: { confirmationPixelPayload, orderInfo },
      firePixelServer,
      onReceiveOrdersByPurchaseId,
      pushMicrosoftUetEvent
    } = this.props;

    const {
      checkoutData: { confirmationPixelPayload: prevConfirmationPixelPayload, orderInfo: prevOrderInfo }
    } = prevProps;

    if (!!orderInfo && !prevOrderInfo) {
      trackEcommOrderConfirmation({ orderInfo });
      // For each order, track all unique shipping dates
      orderInfo.forEach(orders => {
        const { orderId, items } = orders;
        const viewedDates = {};
        const nonElectronicGiftCardItems = items.filter(item => !item.egcInfo);

        nonElectronicGiftCardItems.forEach(item => {
          const {
            shippingInfo: {
              deliveryDateRangeDisplayMessage: otherDeliveryDateRangeDisplayMessage,
              earliestArrivalDate: otherEarliestDate,
              latestArrivalDate: otherLatestArrivaleDate
            }
          } = item;

          if (!viewedDates[otherDeliveryDateRangeDisplayMessage]) {
            onReceiveOrdersByPurchaseId({
              orderId,
              deliveryDateRangeDisplayMessage: otherDeliveryDateRangeDisplayMessage,
              earliestArrivalDate: otherEarliestDate,
              latestArrivalDate: otherLatestArrivaleDate
            });
          }

          viewedDates[otherDeliveryDateRangeDisplayMessage] = true;
        });
      });
    }

    if (confirmationPixelPayload && confirmationPixelPayload !== prevConfirmationPixelPayload) {
      // make order total available to mPulse
      window.orderTotal = confirmationPixelPayload.order.grandTotal;

      pushMicrosoftUetEvent(this.formatMicrosoftUetTagEvent(confirmationPixelPayload));
      firePixelServer('confirmation', confirmationPixelPayload);
    }
  }

  componentWillUnmount() {
    this.props.setHeaderFooterVisibility(true);
  }

  handleShowModal = () => {
    this.setState({ isShowingRecosModal: true });
  };

  handleHideModal = () => {
    this.setState({ isShowingRecosModal: false });
  };

  formatMicrosoftUetTagEvent(pixelPayload) {
    const payloadItems = pixelPayload.order.items || [];
    const stockIds = payloadItems.map(item => item.childStockId);
    const items = payloadItems.map(({ price, childStockId, qty }) => ({
      id: childStockId,
      quantity: qty,
      price
    }));
    return createPurchaseMicrosoftUetEvent(stockIds, pixelPayload.order.subtotal, items);
  }

  getLatestArrivalItem = order =>
    order.items.sort((a, b) => new Date(b.shippingInfo.latestArrivalDate) - new Date(a.shippingInfo.latestArrivalDate))[0];

  onClickContinueShopping = () => {
    trackEvent('TE_ORDER_CONFIRMATION_CONTINUE_SHOPPING');
  };

  onResize = () => {
    const { setHeaderFooterVisibility } = this.props;
    const isInFrame = typeof window !== 'undefined' && inIframe(window);
    const isHideHeaderFooter = isInFrame || !isDesktop();
    setHeaderFooterVisibility(!isHideHeaderFooter);
  };

  make2ColOrder(order) {
    const { items } = order;
    const eGiftCards = filter(items, item => item.egcInfo);
    return eGiftCards.length ? this.makeEGiftCard(order) : this.make2ColRetail(order);
  }

  makeOrder(order) {
    const { items } = order;
    const eGiftCards = filter(items, item => item.egcInfo);
    return eGiftCards.length ? this.makeEGiftCard(order) : this.makeRetail(order);
  }

  getRoktParams(orderInfo) {
    const {
      customerInfo: { email },
      firstName,
      geoCountry
    } = this.props;
    const retailOrders = [];

    orderInfo.forEach(order => {
      const eGiftCards = filter(order.items, item => item.egcInfo);
      if (!eGiftCards.length) {
        retailOrders.push(order);
      }
    });

    if (retailOrders.length) {
      const { orderId } = retailOrders[0];
      return {
        email,
        firstName,
        orderId,
        geoCountry
      };
    }

    return null;
  }

  make2ColRetail(order) {
    const {
      orderId,
      orderDate,
      shipOptionPromise,
      paymentInfo: { paymentType, addCreditCardNumber } = {},
      price: { shipping, subTotal, tax, total },
      items,
      shippingAddress = {}
    } = order;

    const { testId } = this.context;
    const {
      shippingInfo: { deliveryDateRangeDisplayMessage }
    } = this.getLatestArrivalItem(order);
    const formattedAddress = toFormatted(shippingAddress);
    const dateReviewed = new Date(orderDate);
    const dateFormatOptions = {
      month: 'long',
      day: '2-digit',
      year: 'numeric'
    };

    return (
      <>
        <div className={css.twoColumnWrapper} data-test-id={testId('orderDetailsSection')}>
          <div>
            <h2>Order Details</h2>
            <dl className={css.detailRow}>
              <dt>Order Number:</dt>
              <dd>
                <Link className={css.reviewOrderLink} data-test-id={testId('orderReviewLink')} to={`/orders/${orderId}`}>
                  {orderId}
                </Link>
              </dd>
            </dl>
            <dl className={css.detailRow}>
              <dt>Date Ordered:</dt>
              <dd>{dateReviewed.toLocaleString('en-US', dateFormatOptions)}</dd>
            </dl>
            <dl className={css.detailRow}>
              <dt>Shipping Speed:</dt>
              <dd>{shipOptionPromise}</dd>
            </dl>
            <dl className={css.detailRow}>
              <dd className={css.arrivalDate}>{this.makePromise(deliveryDateRangeDisplayMessage)}</dd>
            </dl>
            <dl className={css.detailRow}>
              <dt>Payment Method:</dt>
              <dd>
                {paymentType ? (
                  <>
                    {' '}
                    {paymentType} - ****{addCreditCardNumber}
                  </>
                ) : (
                  <>Gift Card</>
                )}
              </dd>
            </dl>
            <dl className={css.detailRow}>
              <dt>Order Total:</dt>
              <dd>{toUSD(total)}</dd>
            </dl>
          </div>
          <div>
            <div className={css.shipAddressTitle}>Shipping To:</div>
            <MultiLineAddress address={formattedAddress} highlightName={false} />
          </div>
        </div>

        <div className={css.itemsPurchasedWrapper} data-test-id={testId('itemsPurchasedSection')}>
          <h2>Items Purchased</h2>
          <div className={cartCSS.wrapper}>
            <div className={cn(cartCSS.items, css.cartItems)}>
              <div className={cartCSS.heading}>
                <span>Item</span>
                <span>Price</span>
              </div>
              {items.map(item => {
                const { lineItemId, itemQuantity } = item;
                return <CartItem item={item} key={lineItemId} showFixedQuantity={itemQuantity} hideFavorite={true} />;
              })}
            </div>
          </div>
        </div>

        <div className={css.orderTotalWrapper} data-test-id={testId('orderTotalSection')}>
          <dl className={css.detailRow}>
            <dt>Subtotal:</dt>
            <dd>{toUSD(subTotal)}</dd>
          </dl>
          <dl className={css.detailRow}>
            <dt>Shipping:</dt>
            <dd>{toUSD(shipping)}</dd>
          </dl>
          <dl className={css.detailRow}>
            <dt>Tax:</dt>
            <dd>{toUSD(tax)}</dd>
          </dl>
          <dl className={cn(css.detailRow, css.totalRow)}>
            <dt>Order Total:</dt>
            <dd>{toUSD(total)}</dd>
          </dl>
        </div>
      </>
    );
  }

  makeRetail(order) {
    const { orderId, items, shippingAddress = {} } = order;
    const titleLine = `${items.length} ${pluralize('item', items.length)} will be shipped to:`;
    const { testId, marketplace } = this.context;
    const {
      shippingInfo: { deliveryDateRangeDisplayMessage }
    } = this.getLatestArrivalItem(order);
    const formattedAddress = toFormatted(shippingAddress);

    return (
      <>
        <div className={css.orderDetails}>
          <div className={css.orderIdLine} data-cs-mask={marketplace.dataMaskPiiElements}>
            <strong data-test-id={testId('orderId')}>Order: {orderId}</strong>
          </div>
          <div data-test-id={testId('titleLine')}>{titleLine}</div>
          <div className={css.addressBlock} data-cs-mask={marketplace.dataMaskPiiElements}>
            <MultiLineAddress address={formattedAddress} highlightName={false} />
          </div>
          <div className={css.estimatedDeliveryBlock} data-test-id={testId('deliveryDate')}>
            {this.makePromise(deliveryDateRangeDisplayMessage)}
          </div>
        </div>
      </>
    );
  }

  makePromise(message) {
    return <span className={css.dateRange}>{message ?? `We're experiencing shipping delays. We'll email when your order ships.`}</span>;
  }

  makeEGiftCard(order) {
    const { orderId, items } = order;
    const { emailAddress } = items[0].egcInfo;
    const titleLine = `${items.length} gift ${pluralize('card', items.length)} ${pluralize('is', items.length)} being sent to:`;
    const deliveryMsg =
      'Digital items are delivered for free. Upon successful completion of your order, if you picked a future delivery date, it will arrive on that date. If you did not pick a future date, it will typically arrive within 5 minutes of successful completion of your order.';
    const { testId, marketplace } = this.context;

    return (
      <div className={css.orderDetails}>
        <div className={css.orderIdLine} data-cs-mask={marketplace.dataMaskPiiElements}>
          <strong data-test-id={testId('egcOrderId')}>Order: {orderId}</strong>
        </div>
        <div data-test-id={testId('egcTitleLine')}>{titleLine}</div>
        <div className={css.addressBlock} data-test-id={testId('egcEmailAddress')}>
          {emailAddress}
        </div>
        <div className={css.egcDelivery} data-test-id={testId('egcDeliveryMsg')}>
          {deliveryMsg}
        </div>
      </div>
    );
  }

  onSlotClick = e => {
    const { trackLegacyEvent } = this.props;
    const { currentTarget } = e;
    const action = 'OrderConfirmation';
    const label = stripSpecialCharsDashReplace(currentTarget.getAttribute('data-eventlabel'));
    const value = stripSpecialCharsDashReplace(currentTarget.getAttribute('data-eventvalue'));
    e.stopPropagation();
    trackLegacyEvent(action, label, value);
  };

  render() {
    const {
      accountIsLoading,
      customerInfo,
      killswitch: { showOrderConfirmationRoktAds },
      slotData,
      checkoutData: { orderInfo }
    } = this.props;

    const {
      marketplace: {
        orderConfirmation: { allowRoktAds, line1Text, line2Text, line3Text, showContactUs, showVoc, showActionButtons, is2ColLayout }
      }
    } = this.context;

    const isInFrame = inIframe();

    const showRoktAds = !isInFrame && allowRoktAds && showOrderConfirmationRoktAds;

    if (!orderInfo || (showRoktAds && accountIsLoading && !customerInfo)) {
      return <Loader />;
    }

    const roktParams = showRoktAds ? this.getRoktParams(orderInfo) : null;
    const { testId } = this.context;
    const reviewLink = orderInfo.length > 1 ? '/account' : `/orders/${orderInfo[0].orderId}`;
    const reviewLinkText = orderInfo.length > 1 ? 'Review or edit your recent orders' : 'Review or edit your order';

    const headerText = (
      <>
        <h1>{line1Text}</h1>
        {!!line2Text && <p className={css.line2Text}>{line2Text}</p>}
        {!!line3Text && <p>{line3Text}</p>}
      </>
    );

    return (
      <SiteAwareMetadata>
        <div className={css.page}>
          {showRoktAds && roktParams && <RoktAds {...roktParams} />}
          <div className={css.smallScreenOnly}>{headerText}</div>

          <div className={css.container}>
            <div className={cn(css.desktopOnly, css.orderPlaced)}>{headerText}</div>

            {orderInfo.map((order, i) => (
              <div key={`order-${i}`} data-test-id={testId('orderBlock')}>
                {is2ColLayout ? this.make2ColOrder(order) : this.makeOrder(order)}
              </div>
            ))}

            {showActionButtons && (
              <div className={css.actions}>
                <Link className={css.reviewOrderBtn} data-test-id={testId('orderReviewLink')} to={reviewLink}>
                  {reviewLinkText}
                </Link>

                <div>or</div>

                <Link
                  className={css.continueBtn}
                  data-test-id={testId('continueShoppingTop')}
                  to="/?referrer=thankyou"
                  onClick={this.onClickContinueShopping}
                >
                  Continue Shopping
                </Link>
              </div>
            )}
          </div>

          {!!slotData['primary-1'] && (
            <div className={css.primary1Wrapper}>
              <LandingSlot data={slotData['primary-1']} onComponentClick={this.onSlotClick} />
            </div>
          )}
          <div>
            {showContactUs && <ContactUs />}
            {showVoc && <VoiceOfCustomer />}
          </div>
          {!!slotData['primary-2'] && <LandingSlot data={slotData['primary-2']} onComponentClick={this.onSlotClick} />}
        </div>
      </SiteAwareMetadata>
    );
  }
}

export const getCountryFromGeoCookie = (geoCookie = 'US') => geoCookie.split('/')[0] || 'US';

const mapStateToProps = state => {
  const {
    account: { isLoading: accountIsLoading, customerInfo },
    checkoutData,
    cookies: { geo } = {},
    holmes,
    killswitch,
    landingPage: { pageInfo: { slotData = {} } = {} },
    recos
  } = state;

  const geoCountry = getCountryFromGeoCookie(geo);
  const firstName = holmes?.firstName || '';

  return {
    accountIsLoading,
    checkoutData,
    customerInfo,
    firstName,
    killswitch,
    recos,
    slotData,
    geoCountry
  };
};

OrderConfirmation.contextTypes = {
  testId: PropTypes.func,
  marketplace: PropTypes.object
};

OrderConfirmation.defaultProps = {
  trackEvent,
  trackLegacyEvent
};

export default connect(mapStateToProps, {
  fetchAccountInfo,
  fetchSymphonyContentByPageLayout,
  firePixelServer,
  onFetchOrdersByPurchaseId,
  onReceiveOrdersByPurchaseId,
  pushMicrosoftUetEvent,
  resetLandingPageInfo,
  setHeaderFooterVisibility,
  setLastPageWasOrderconfirmation,
  triggerAssignment
})(OrderConfirmation);
