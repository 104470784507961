import type { ChangeEvent, SyntheticEvent } from 'react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { deepEqual } from 'fast-equals';

import { cn } from 'helpers/classnames';
import type { AddReviewState } from 'reducers/reviews/addReview';
import { IMAGE, MSA_CC_IMAGES_URL, SESSION_STORAGE_REVIEW_KEY, VIDEO } from 'constants/appConstants';
import { omit } from 'helpers/lodashReplacement';
import { getFromSessionStorage, saveToSessionStorage, smoothScroll } from 'helpers/ClientUtils';
import MartyLink from 'components/common/MartyLink';
import AddReviewMediaUpload from 'components/reviews/AddReviewMediaUpload';
import AddReviewRating from 'components/reviews/AddReviewRating';
import AddReviewFitting from 'components/reviews/AddReviewFitting';
import AddReviewAbout from 'components/reviews/AddReviewAbout';
import ProductUtils from 'helpers/ProductUtils';
import { ButtonSpinner } from 'components/Loader';
import { track } from 'apis/amethyst';
import { evAttachMedia, evAttachMediaClick } from 'events/productReview';
import type { MediaSourceType } from 'types/amethyst';
import type { LegacyContext } from 'types/context';

import css from 'styles/components/reviews/addReviewForm.scss';

const MAX_SUMMARY_LENGTH = 10000;

interface AddReviewFormProps {
  product: {
    defaultProductType: string;
    isReviewableWithMedia: boolean;
    isWearable: boolean;
  };
  returnTo: string;
  addReview: AddReviewState;
  mediaOnly: boolean;
  boolMediaUpload: boolean;
  productLink: JSX.Element | undefined;
  params: UrlParams;
  initialStarRating?: string;
  onAddFieldValidationErrors: Function;
  onSubmitProductReview: Function;
  onUpdateSummary: Function;
  onUpdateRating: Function;
  onUpdateFit: Function;
  onUpdateAbout: Function;
}

interface UrlParams {
  productId: string;
  colorId: string;
  p: string;
}

const defaultProps = {
  smoothScroll, // allow mocking of smoothScroll for unit tests
  track
};

const defaultState = {
  images: [],
  video: '',
  mounted: false,
  incentivized: false
};

type Props = typeof defaultProps & AddReviewFormProps;
interface State {
  images: string[];
  video: string;
  mounted: boolean;
  incentivized: boolean;
}

export class AddReviewForm extends Component<Props, State> {
  static contextTypes = {
    testId: PropTypes.func.isRequired
  };
  static defaultProps = defaultProps;
  state = defaultState;

  componentDidMount() {
    const { onUpdateSummary, onUpdateRating, onUpdateFit, onUpdateAbout, smoothScroll, initialStarRating } = this.props;
    const persistedState: any = getFromSessionStorage(SESSION_STORAGE_REVIEW_KEY);
    this.setState({ mounted: true });

    Object.keys(persistedState).length > 0 && smoothScroll.scrollTo(this.reviewsSections);

    const { summary, overallRating, comfortRating, lookRating, shoeSize, shoeWidth, shoeArch, reviewerName, reviewerLocation, otherShoes } =
      persistedState;

    if (summary) {
      onUpdateSummary(summary.slice(0, MAX_SUMMARY_LENGTH));
    }
    if (overallRating && overallRating !== '0') {
      onUpdateRating({ name: 'overallRating', value: overallRating });
    }
    if (comfortRating && comfortRating !== '0') {
      onUpdateRating({ name: 'comfortRating', value: comfortRating });
    }
    if (lookRating && lookRating !== '0') {
      onUpdateRating({ name: 'lookRating', value: lookRating });
    }
    if (shoeSize) {
      onUpdateFit({ name: 'shoeSize', value: shoeSize });
    }
    if (shoeWidth) {
      onUpdateFit({ name: 'shoeSize', value: shoeWidth });
    }
    if (shoeArch) {
      onUpdateFit({ name: 'shoeSize', value: shoeArch });
    }
    if (reviewerName) {
      onUpdateAbout({ name: 'reviewerName', value: reviewerName });
    }
    if (reviewerLocation) {
      onUpdateAbout({ name: 'reviewerLocation', value: reviewerLocation });
    }
    if (otherShoes) {
      onUpdateAbout({ name: 'otherShoes', value: otherShoes });
    }

    // Fill in star rating for all ratings if the url parameter exists
    if (initialStarRating && initialStarRating !== '0') {
      onUpdateRating({ name: 'overallRating', value: initialStarRating });
      onUpdateRating({ name: 'comfortRating', value: initialStarRating });
      onUpdateRating({ name: 'lookRating', value: initialStarRating });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { addReview } = this.props;
    // Save form to session storage on change
    if (!deepEqual(addReview, prevProps.addReview)) {
      saveToSessionStorage(SESSION_STORAGE_REVIEW_KEY, JSON.stringify(addReview));
    }
  }

  reviewsSections: HTMLOListElement | null = null;

  onAboutChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onUpdateAbout } = this.props;
    const name = event.target.getAttribute('data-about');
    onUpdateAbout({ name, value: event.target.value });
  };

  onFitChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { onUpdateFit } = this.props;
    const name = event.target.getAttribute('data-fitting');
    onUpdateFit({ name, value: event.target.value });
  };

  onMediaAttachment = ({ images, video }: { images?: string[]; video?: string }, mediaSourceType?: MediaSourceType) => {
    if (video !== undefined) {
      // This function handling both insertion and deletion is not ideal
      this.setState(state => ({ ...state, video }));
      this.trackAttachMedia(VIDEO, mediaSourceType);
    }

    if (images) {
      this.setState(state => ({ ...state, images }));
      images.forEach(() => this.trackAttachMedia(IMAGE, mediaSourceType));
    }
  };

  trackAttachMedia = (mediaType: string, mediaSourceType?: MediaSourceType) => {
    const {
      params: { productId, colorId },
      track
    } = this.props;
    track(() => [
      evAttachMedia,
      {
        productId,
        colorId,
        mediaType,
        mediaSourceType
      }
    ]);
  };

  onRatingChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onUpdateRating } = this.props;
    const name = event.target.getAttribute('data-rating');
    onUpdateRating({ name, value: event.target.value });
  };

  onReviewSummaryChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { onUpdateSummary } = this.props;
    const {
      target: { value, maxLength }
    } = event;
    if (value.length <= maxLength) {
      onUpdateSummary(value);
    }
  };

  onSubmitReview = () => {
    const { images, video, incentivized } = this.state;
    const {
      params: { productId, colorId, p: isPremierReviewer },
      product: { defaultProductType },
      returnTo,
      addReview,
      onSubmitProductReview
    } = this.props;
    let customerReview;
    customerReview = omit(addReview, ['errorMap', 'isLoading', 'isSubmitted']) as Omit<AddReviewState, 'errorMap' | 'isLoading' | 'isSubmitted'>;
    if (!ProductUtils.isShoeType(defaultProductType)) {
      customerReview = omit(customerReview, ['shoeSize', 'shoeWidth', 'shoeArch', 'otherShoes']) as Omit<
        AddReviewState,
        'errorMap' | 'isLoading' | 'isSubmitted' | 'shoeSize' | 'shoeWidth' | 'shoeArch' | 'otherShoes'
      >;
    }
    const reviewMedia = { images, video };
    const reviewData = {
      productId,
      ...customerReview,
      premier: !!isPremierReviewer,
      incentivized
    };
    if (colorId && !isNaN(parseInt(colorId))) {
      reviewData.colorId = colorId;
    }
    onSubmitProductReview({ reviewData, reviewMedia, returnTo });
  };

  onSubmitValidate = (e: SyntheticEvent) => {
    e.preventDefault();
    const { addReview, mediaOnly, onAddFieldValidationErrors, smoothScroll } = this.props;
    const { summary, overallRating, comfortRating, lookRating } = addReview;
    const { images, incentivized, video } = this.state;

    const errorMap: {
      overallRating?: boolean;
      comfortRating?: boolean;
      lookRating?: boolean;
      summary?: boolean;
      media?: boolean;
    } = {};

    if (!mediaOnly) {
      if (overallRating === '0') {
        errorMap.overallRating = true;
      }
      if (comfortRating === '0') {
        errorMap.comfortRating = true;
      }
      if (lookRating === '0') {
        errorMap.lookRating = true;
      }
      if (!summary) {
        errorMap.summary = true;
      }
    }

    if ((mediaOnly || incentivized) && images.length === 0 && !video) {
      errorMap.media = true;
    }
    if (Object.keys(errorMap).length > 0) {
      onAddFieldValidationErrors(errorMap);
      smoothScroll.scrollTo(this.reviewsSections);
    } else {
      this.onSubmitReview();
    }
  };

  onIncentivizedReviewToggle = (e: ChangeEvent<HTMLInputElement>) => void this.setState({ incentivized: e.target.checked });

  onAttachMediaClick = (mediaSourceType?: MediaSourceType) => {
    const {
      params: { productId, colorId },
      track
    } = this.props;
    track(() => [evAttachMediaClick, { productId, colorId, mediaSourceType }]);
  };

  shouldShowMediaUpload = () => {
    const {
      boolMediaUpload,
      product: { isReviewableWithMedia }
    } = this.props;
    const { mounted } = this.state;
    return boolMediaUpload ? isReviewableWithMedia && mounted : isReviewableWithMedia && mounted;
  };

  makeRatingStep = () => {
    const { addReview, product } = this.props;
    const { testId } = this.context as LegacyContext;
    const { errorMap, overallRating, comfortRating, lookRating, shoeSize, shoeWidth, shoeArch } = addReview;
    const { defaultProductType } = product;
    const ratings = { overallRating, comfortRating, lookRating };
    const isShoeType = ProductUtils.isShoeType(defaultProductType);
    return (
      <li className={css.numberedStep}>
        <div className={cn(css.fieldSection, css.ratingSection)}>
          <h2 className={css.fieldLabel} data-test-id={testId('reviewQ1')}>
            How did you feel about this item?
          </h2>
          <AddReviewRating ratings={ratings} errors={errorMap} onRatingChange={this.onRatingChange} />
          {isShoeType && (
            <>
              <h3 className={cn(css.fieldLabel, css.shoeDimensionsLabel)}>What about the fit?</h3>
              <AddReviewFitting shoeSize={shoeSize} shoeWidth={shoeWidth} shoeArch={shoeArch} onFitChange={this.onFitChange} />
            </>
          )}
        </div>
      </li>
    );
  };

  makeSummaryStep = () => {
    const {
      addReview: { errorMap, summary },
      product: { isWearable }
    } = this.props;
    const { testId } = this.context as LegacyContext;
    const placeholder = isWearable
      ? 'How was the fit and size? What does the material feel like? How awesome (or not) do you feel wearing them?'
      : 'How did you like the product?';
    return (
      <li className={css.numberedStep}>
        <div className={css.fieldSection}>
          <h2 className={css.fieldLabel}>Let us know what you think</h2>
          <label className={css.summaryMelodyInputLabel} htmlFor="summary">
            Your Review
          </label>
          <textarea
            id="summary"
            className={cn(css.summaryField, { [css.error]: errorMap.summary })}
            data-test-id={testId('summary')}
            maxLength={MAX_SUMMARY_LENGTH}
            placeholder={placeholder}
            onChange={this.onReviewSummaryChange}
            value={summary}
            required
          />
        </div>
      </li>
    );
  };

  makeMediaUploadStep = ({ outfitEncouragement }: { outfitEncouragement?: boolean } = {}) => {
    const {
      addReview: { errorMap },
      productLink,
      mediaOnly
    } = this.props;
    const { images, incentivized, video } = this.state;
    const mediaError = errorMap.media
      ? incentivized
        ? 'Media upload is required to enter the sweepstakes'
        : 'Please upload a photo or video'
      : undefined;
    const required = mediaOnly || incentivized;

    if (!this.shouldShowMediaUpload()) {
      return null;
    }
    return (
      <li className={css.numberedStep}>
        <div className={cn(css.fieldSection, css.uploadFieldSection)}>
          <span className={css.fieldLabel}>
            {mediaOnly ? <>Show us how you wear the {productLink}</> : 'Upload photos and videos'}
            {!required && <span className={css.fieldLabel}> (optional)</span>}
          </span>
          {!outfitEncouragement && (
            <div className={cn(css.fieldLabel, css.removeFieldLabelMargin)}>
              You can drag &amp; drop or click below to attach up to four images and/or one video
            </div>
          )}
          <AddReviewMediaUpload
            images={images}
            video={video}
            onMediaAttachment={this.onMediaAttachment}
            onAttachMediaClick={this.onAttachMediaClick}
            outfitEncouragement={outfitEncouragement}
            error={mediaError}
            required={required}
          />
          {outfitEncouragement && this.makeOutfitEncouragementIdeas()}
        </div>
      </li>
    );
  };

  makeOutfitEncouragementIdeas = () => (
    <div className={css.outfitEncouragementWrapper}>
      <h2>Ideas to get you started</h2>
      <div className={css.tipWrapper}>
        <div className={css.outfitTip}>
          <img alt="example outfit 1" src={`${MSA_CC_IMAGES_URL}9084541-e2446f3._CR350,220,1040,1250_.jpg`} />
          <p className={css.tipText}>Take a head-to-toe photo of your outfit.</p>
        </div>
        <div className={css.outfitTip}>
          <img alt="example outfit 2" src={`${MSA_CC_IMAGES_URL}8811536-eb6a7f0._CR500,50,500,600_.jpg`} />
          <p className={css.tipText}>Get creative! Strike a pose or do a runway walk.</p>
        </div>
        <div className={css.outfitTip}>
          <img alt="example outfit 3" src={`${MSA_CC_IMAGES_URL}8075935-335f092._CR400,50,500,600_.jpg`} />
          <p className={css.tipText}>Use very little or no filters. We want the real you.</p>
        </div>
      </div>
    </div>
  );

  makeAboutYourselfStep = () => {
    const { testId } = this.context as LegacyContext;
    const { addReview } = this.props;
    const { reviewerName, reviewerLocation, otherShoes } = addReview;
    return (
      <li className={css.numberedStep}>
        <div className={css.fieldSection}>
          <h2 className={cn(css.fieldLabel, css.aboutSectionLabel)} data-test-id={testId('aboutReviewerHeader')}>
            Tell us a little about yourself
          </h2>
          <AddReviewAbout
            reviewerName={reviewerName}
            reviewerLocation={reviewerLocation}
            otherShoes={otherShoes}
            onAboutChange={this.onAboutChange}
          />
        </div>
      </li>
    );
  };

  makeSubmitStep = () => {
    const { testId } = this.context as LegacyContext;
    const {
      addReview: { isLoading },
      mediaOnly
    } = this.props;

    return (
      <li>
        <p className={css.bottomNote}>
          Note: By submitting a {mediaOnly ? 'photo or video' : 'review'} you represent and warrant that you are at least 18 years old. Please visit
          our <MartyLink to="/c/customer-reviews">Review Guidelines</MartyLink> for full guidelines on writing reviews
          {mediaOnly && ' or submitting photos or videos'}. All content (including text, images and videos) becomes our sole and exclusive property
          pursuant to our <MartyLink to="/c/terms-of-use">Terms of Use</MartyLink>. Your {mediaOnly ? 'submissions' : 'reviews'} will be processed
          within five days.
        </p>
        <div className={css.fieldSection}>
          <button type="submit" className={css.submitButton} disabled={isLoading} data-test-id={testId('submitReviewButton')}>
            {this.makeSubmitButtonLabel()}
          </button>
        </div>
      </li>
    );
  };

  makeSubmitButtonLabel = () => {
    const {
      addReview: { isLoading },
      mediaOnly
    } = this.props;

    if (isLoading) {
      return <ButtonSpinner size="15" />;
    }

    return mediaOnly ? 'Submit Your Outfit' : 'Submit Review';
  };

  makeMediaOnlyForm = () => (
    <>
      {this.makeMediaUploadStep({ outfitEncouragement: true })}
      {this.makeSubmitStep()}
    </>
  );

  makeFullReviewForm = () => (
    <>
      {this.makeRatingStep()}
      {this.makeSummaryStep()}
      {this.makeMediaUploadStep()}
      {this.makeAboutYourselfStep()}
      {this.makeSubmitStep()}
    </>
  );

  render() {
    const { mediaOnly } = this.props;
    return (
      <form noValidate method="post" onSubmit={this.onSubmitValidate}>
        <ol
          className={cn(css.stepList, {
            [css.stepListMediaOnly]: mediaOnly,
            [css.stepListFull]: !mediaOnly
          })}
          ref={el => (this.reviewsSections = el)}
        >
          {mediaOnly ? this.makeMediaOnlyForm() : this.makeFullReviewForm()}
        </ol>
      </form>
    );
  }
}

export default AddReviewForm;
