import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import InfluencerLinks from 'components/influencer/InfluencerLinks';
import { loadInfluencerLandingPage, redirectBasedOnInfluencerDetails } from 'actions/influencer/influencer';
import { redirectTo } from 'actions/redirect';
import { titaniteView } from 'apis/amethyst';
import SiteAwareMetadata from 'components/SiteAwareMetadata';
import InfluencerEligibility from 'components/influencer/InfluencerEligibility';
import InfuencerCreateAccount from 'components/influencer/InfuencerCreateAccount';
import InfuencerEnrollExistingAccount from 'components/influencer/InfuencerEnrollExistingAccount';
import { InfluencerHeaderFooterWrapper } from 'containers/influencer/InfluencerHeaderFooterWrapper';
import { setHeaderFooterVisibility } from 'actions/headerfooter';
import LandingSlot from 'containers/LandingSlot';
import { fetchCustomerAuthDetails } from 'actions/authentication';
import InfluencerFaqs from 'components/influencer/landing/InfluencerFaqs';
import InfluencerLandingSteps from 'components/influencer/landing/InfluencerLandingSteps';
import InfluencerLandingSignup from 'components/influencer/landing/InfluencerLandingSignup';
import InfluencerLandingPageHeading from 'components/influencer/landing/InfluencerLandingPageHeading';
import InfluencerEnrollFormV2 from 'components/influencer/InfluencerEnrollFormV2';
import { INFLUENCER_PAGES_DETAILS, INFLUENCER_SIGNUP_PAGE } from 'constants/influencerPages';

import css from 'styles/containers/influencer/influencerLanding.scss';

export const InfluencerLanding = props => {
  const {
    loadInfluencerLandingPage,
    setHeaderFooterVisibility,
    fetchCustomerAuthDetails,
    redirectBasedOnInfluencerDetails,
    redirectTo,
    pageInfo: { slotData = {} },
    slotOrder = [],
    path: currentPagePath
  } = props;
  const {
    [currentPagePath]: { pageName, isLandingPage, needsAuthentication }
  } = INFLUENCER_PAGES_DETAILS;
  const slotContentTypes = {
    influencerLandingSignup: InfluencerLandingSignup,
    influencerEnrollmentSteps: InfluencerLandingSteps,
    faqs: InfluencerFaqs,
    influencerPageHeading: InfluencerLandingPageHeading,
    influencerCreateAccount: InfuencerCreateAccount,
    influencerEnrollExistingAccount: InfuencerEnrollExistingAccount,
    influencerEligibility: InfluencerEligibility,
    influencerLinks: InfluencerLinks
  };

  slotContentTypes['influencerEnrollFormV2'] = InfluencerEnrollFormV2;

  useEffect(() => {
    titaniteView();
    loadInfluencerLandingPage(pageName);
  }, [pageName, loadInfluencerLandingPage]);

  useEffect(() => {
    if (needsAuthentication) {
      fetchCustomerAuthDetails().then(({ success }) => {
        if (!success) {
          redirectTo(INFLUENCER_SIGNUP_PAGE);
        } else {
          redirectBasedOnInfluencerDetails();
        }
      });
    }
  }, [needsAuthentication, fetchCustomerAuthDetails, redirectTo, redirectBasedOnInfluencerDetails]);

  const contentSlots = slotOrder.filter(slotName => slotName !== 'primary-1' && slotName !== 'primary-19' && slotName !== 'primary-20');
  return (
    <SiteAwareMetadata>
      <InfluencerHeaderFooterWrapper setHeaderFooterVisibility={setHeaderFooterVisibility} isLandingPage={isLandingPage} slotData={slotData}>
        <div className={css.container}>
          {Array.isArray(contentSlots) &&
            contentSlots.map(slotName => (
              <LandingSlot
                key={slotName}
                data={slotData[slotName]}
                slotContentTypesList={slotData[slotName].componentName in slotContentTypes ? slotContentTypes : undefined}
              />
            ))}
        </div>
      </InfluencerHeaderFooterWrapper>
    </SiteAwareMetadata>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    landingPage: { pageInfo = {}, slotOrder }
  } = state;
  const {
    match: { path }
  } = ownProps;
  return { pageInfo, slotOrder, path };
};

const mapDispatchToProps = {
  setHeaderFooterVisibility,
  loadInfluencerLandingPage,
  redirectTo,
  fetchCustomerAuthDetails,
  redirectBasedOnInfluencerDetails
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(InfluencerLanding);
