import React from 'react';

import MartyLink from 'components/common/MartyLink';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/influencer/influencerLogoHeader.scss';

export const InfluencerHeader = props => {
  const { testId } = useMartyContext();
  const { slotData: { 'primary-1': { logourl, logolink, secondarytext, signinlink, signintext, logoalttext } = {} } = [], isLandingPage } =
    props || {};

  const makealreadyInfluencerLink = () => (
    <div className={css.signin}>
      {secondarytext}{' '}
      <MartyLink data-test-id={testId('influencerHomeSignInLink')} to={signinlink}>
        {signintext}
      </MartyLink>
    </div>
  );

  return (
    <header className={css.header}>
      <div className={css.logo}>
        <MartyLink data-test-id={testId('influencerHomeLink')} to={logolink}>
          <img src={logourl} alt={logoalttext} />
        </MartyLink>
      </div>
      {isLandingPage && makealreadyInfluencerLink()}
    </header>
  );
};
export default InfluencerHeader;
