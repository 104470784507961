import React from 'react';

import MartyLink from 'components/common/MartyLink';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/influencer/influencerLandingSignup.scss';

const InfluencerLandingSignup = props => {
  const { testId } = useMartyContext();
  const { slotDetails } = props;
  const { heading, signuplink, signuptext, secondarytext, secondarylinktext } = slotDetails || {};

  return (
    <div>
      <h2 className={css.heading}>{heading}</h2>
      <MartyLink data-test-id={testId('influencerHomeSignUpLink')} className={css.signupBtn} to={signuplink} type={'button'}>
        {signuptext}
      </MartyLink>
      <p className={css.signin}>
        {secondarytext}{' '}
        <MartyLink data-test-id={testId('influencerHomeSecondarySignUpLink')} to={signuplink}>
          {' '}
          {secondarylinktext}
        </MartyLink>
      </p>
    </div>
  );
};
export default withErrorBoundary('InfluencerLandingSignup', InfluencerLandingSignup);
