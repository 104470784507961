import type { ReactNode } from 'react';

import { cn } from 'helpers/classnames';
import type { Address } from 'types/checkout';
import useMartyContext from 'hooks/useMartyContext';
import type TagProps from 'tailwind/components/Tag/Tag.types';

import css from 'styles/components/checkout/address/multiLineAddress.scss';

interface MultiLineAddressProps {
  address: Partial<Address>;
  hideCountry?: boolean;
  hideName?: boolean;
  hidePhone?: boolean;
  highlightName?: boolean;
  Tag?: TagProps;
}

const MultiLineAddress = ({
  address = {},
  hideCountry = false,
  hideName = false,
  hidePhone = false,
  highlightName = true,
  Tag
}: MultiLineAddressProps) => {
  const {
    marketplace: { dataMaskPiiElements },
    testId
  } = useMartyContext();

  if (!Object.keys(address).length || !address.mailingAddress || !address.name || !address.phone) {
    return null;
  }

  const {
    mailingAddress: { addressLine1, addressLine2, city, countryCode, countryName, postalCode, stateOrRegion },
    name: { fullName },
    phone: {
      voice: {
        primary: { number: phoneNumber }
      }
    }
  } = address;

  if (!addressLine1) {
    return null;
  }

  const nameStyles = cn({ [css.fullName]: highlightName });

  return (
    <div className={css.wrapper} data-test-id={testId('multiLineAddress')} data-cs-mask={dataMaskPiiElements}>
      {!hideName && (
        <div className={nameStyles} data-test-id={testId('mlaName')}>
          {fullName}
          {Tag as ReactNode}
        </div>
      )}
      <div className={css.addressLine} data-test-id={testId('mlaLine1')}>
        {addressLine1}
      </div>
      {addressLine2 && (
        <div className={css.addressLine} data-test-id={testId('mlaLine2')}>
          {addressLine2}
        </div>
      )}
      <div className={css.addressLine} data-test-id={testId('mlaLine3')}>
        {city}, {stateOrRegion} {postalCode}
      </div>
      {!hideCountry && (
        <div className={css.addressLine} data-test-id={testId('mlaCountry')}>
          {countryName || countryCode}
        </div>
      )}
      {!hidePhone && (
        <div className={css.addressLine} data-test-id={testId('mlaPhone')}>
          {phoneNumber}
        </div>
      )}
    </div>
  );
};

export default MultiLineAddress;
