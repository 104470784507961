import React from 'react';

import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/influencer/influencerLandingSteps.scss';

const InfluencerLandingSteps = props => {
  const { testId } = useMartyContext();
  const { slotDetails } = props;
  const { steps } = slotDetails;

  const makeStep = step => {
    const { id, color, header, description } = step;
    return (
      <li key={id} style={{ '--step-background': color }}>
        <span data-test-id={testId('influencerLandingStepsHeader')} className={css.header}>
          {header}
        </span>
        <p data-test-id={testId('influencerLandingStepsDescription')} className={css.description}>
          {description}
        </p>
      </li>
    );
  };

  return <ol className={css.steps}>{steps.map(makeStep)}</ol>;
};
export default withErrorBoundary('InfluencerLandingSteps', InfluencerLandingSteps);
