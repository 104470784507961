import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { cn } from 'helpers/classnames';
import { INFLUENCER_ENROLL_CALLBACK } from 'constants/influencerPages';
import { INFLUENCER_SIGNUP_METHOD_TYPE } from 'constants/amethystEnums';
import MartyLink from 'components/common/MartyLink';
import { fetchCustomerAuthDetails } from 'actions/authentication';
import { fetchAccountInfo } from 'actions/account/account';
import { track } from 'apis/amethyst';
import { evSignupInfluencer } from 'events/influencer';
import { clearCartLocalStorage } from 'helpers/CartUtils';
import HtmlToReact from 'components/common/HtmlToReact';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/influencer/infuencerEnrollExistingAccount.scss';

export const InfuencerEnrollExistingAccount = props => {
  const { testId } = useMartyContext();
  const {
    slotDetails: { heading, copy, signingooglecta, signinamazoncta, signinzapposcta, alreadysignincta, signoutcta, termsandpolicytext },
    customerInfo,
    authentication,
    fetchAccountInfo,
    fetchCustomerAuthDetails
  } = props;
  const {
    marketplace: {
      desktopBaseUrl,
      features: {
        federatedLogin: { zapposAuthUrl, amazonAuthUrl, googleAuthUrl }
      },
      dataMaskPiiElements
    }
  } = useMartyContext();
  const [isOptionsExpanded, setIsOptionsExpanded] = useState(false);
  const returnTo = encodeURIComponent(INFLUENCER_ENROLL_CALLBACK);
  const tokenExchangeRedirect = false;

  useEffect(() => {
    fetchCustomerAuthDetails().then(response => {
      const { success } = response;
      success && fetchAccountInfo();
    });
  }, [fetchAccountInfo, fetchCustomerAuthDetails]);

  const expandOption = () => {
    setIsOptionsExpanded(!isOptionsExpanded);
  };
  const handleSignOutClick = event => {
    event.preventDefault();
    clearCartLocalStorage();
    window.location.href = `${desktopBaseUrl}/logout`;
  };

  const onSignupClick = e => {
    const { signupMethodType } = e.target.dataset;
    track(() => [evSignupInfluencer, { signupMethodType }]);
  };

  const makeOtherLoginButtons = () => (
    <div className={css.otherLoginButtons}>
      <MartyLink
        data-test-id={testId('influencerAmazonSignInLink')}
        to={`${amazonAuthUrl}${returnTo}&tokenExchangeRedirect=${tokenExchangeRedirect}`}
        onClick={onSignupClick}
        data-signup-method-type={INFLUENCER_SIGNUP_METHOD_TYPE.SIGN_IN_WITH_AMAZON}
        className={cn(css.icon, css.amazonIcon)}
      >
        {signinamazoncta}
      </MartyLink>

      <MartyLink
        data-test-id={testId('influencerGoogleSignInLink')}
        to={`${googleAuthUrl}${returnTo}&tokenExchangeRedirect=${tokenExchangeRedirect}`}
        onClick={onSignupClick}
        data-signup-method-type={INFLUENCER_SIGNUP_METHOD_TYPE.SIGN_IN_WITH_GOOGLE}
        className={cn(css.icon, css.googleIcon)}
      >
        {signingooglecta}
      </MartyLink>
    </div>
  );

  const makeUserNotSignedIn = () => (
    <div>
      <MartyLink
        data-test-id={testId('influencerZapposSignInLink')}
        to={`${zapposAuthUrl}${returnTo}&tokenExchangeRedirect=${tokenExchangeRedirect}`}
        onClick={onSignupClick}
        data-signup-method-type={INFLUENCER_SIGNUP_METHOD_TYPE.SIGN_IN_WITH_ZAPPOS}
        className={cn(css.icon, css.zapposIcon)}
      >
        {signinzapposcta}
      </MartyLink>

      <details type={'button'} className={cn(css.signinOptions, isOptionsExpanded ? css.arrowUp : css.arrowDown)}>
        <summary
          data-test-id={testId('influencerExistingAccountOptions')}
          onClick={expandOption}
          onKeyDown={expandOption}
          role="button"
          tabIndex={0}
        >
          More Sign-in Options
        </summary>
        {isOptionsExpanded && makeOtherLoginButtons()}
      </details>
    </div>
  );

  const makeUserSignedIn = () => (
    <div>
      <MartyLink
        data-test-id={testId('influencerExistingAccountLink')}
        to={'/influencer/enroll'}
        onClick={onSignupClick}
        data-signup-method-type={INFLUENCER_SIGNUP_METHOD_TYPE.SIGN_IN_WITH_ZAPPOS}
        className={css.continueBtn}
      >
        {alreadysignincta}
      </MartyLink>
      <MartyLink data-test-id={testId('influencerExistingAccountLogOutLink')} onClick={handleSignOutClick} to="/logout">
        <span data-cs-mask={dataMaskPiiElements}>{customerInfo && `Not ${customerInfo.email}? ${signoutcta}`}</span>
      </MartyLink>
    </div>
  );

  return (
    <section className={css.divider}>
      <h2 data-test-id={testId('influencerExistingAccountHeading')} className={css.heading}>
        {heading}
      </h2>
      <p data-test-id={testId('influencerExistingAccountCopy')} className={css.copy}>
        {copy}
      </p>
      {authentication ? makeUserSignedIn() : makeUserNotSignedIn()}
      {!authentication && (
        <HtmlToReact data-test-id={testId('influencerExistingAccountPolicy')} className={css.terms}>
          {termsandpolicytext}
        </HtmlToReact>
      )}
    </section>
  );
};
const mapStateToProps = state => {
  const {
    account: { customerInfo },
    authentication
  } = state;
  return {
    customerInfo,
    authentication
  };
};
const connector = connect(mapStateToProps, {
  fetchCustomerAuthDetails,
  fetchAccountInfo
});
export default connector(InfuencerEnrollExistingAccount);
