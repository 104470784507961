import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';

import { cn } from 'helpers/classnames';
import ReviewYourPurchases from 'components/reviews/ReviewYourPurchases';
import HappyFeatureFeedback from 'components/HappyFeatureFeedback';
import { MEDIA_UPLOAD_PAGE, WRITE_PRODUCT_REVIEW_PAGE } from 'constants/amethystPageTypes';
import { MEDIA_UPLOAD_EXPERIENCE_FEEDBACK, PRODUCT_REVIEW_EXPERIENCE_FEEDBACK } from 'constants/amethystSentimentFeedbackSources';
import useMartyContext from 'hooks/useMartyContext';
import { removeFromSessionStorage } from 'helpers/ClientUtils';
import { SESSION_STORAGE_REVIEW_KEY } from 'constants/appConstants';
import useEffectOnce from 'hooks/useEffectOnce';
import { getCustomerReviewCount } from 'apis/cloudreviews';
import type { CloudReviewCount } from 'types/cloudReviews';
import { ReviewStatusTypes } from 'types/cloudReviews';
import { Loader } from 'components/Loader';
import { selectMafiaConfig } from 'selectors/environment';
import type { AppState } from 'types/app';

const ReviewConfirmationLlama = loadable(() => import('components/animations/ReviewConfirmationLlama'));
const MultipleReviewsConfirmationLlama = loadable(() => import('components/animations/MultipleReviewsConfirmationLlama'));

import css from 'styles/components/reviews/addReviewConfirmation.scss';

type AddReviewConfirmationProps = {
  productId: string;
  mediaOnly: boolean;
  productLink: JSX.Element | undefined;
};

const AddReviewConfirmation = ({ productId, mediaOnly, productLink }: AddReviewConfirmationProps) => {
  const { testId } = useMartyContext();
  const cookies = useSelector((state: AppState) => state.cookies);
  const { url: mafiaUrl } = useSelector(selectMafiaConfig);
  const [passedSubmissions, setPassedSubmission] = useState(0);
  const [reviewsFetched, setReviewsFetched] = useState(false);

  const preambleText = mediaOnly
    ? 'Thanks for sharing your outfit! It may take a few days to see it posted. Head over to '
    : ((passedSubmissions <= 1 && 'Thanks for your review!') ||
        (passedSubmissions > 1 && 'Thanks for your support by providing your feedback on time and time again!')) +
      ' Check back here in a few days to see it posted. Head over to ';
  useEffectOnce(() => {
    removeFromSessionStorage(SESSION_STORAGE_REVIEW_KEY);
  });

  useEffect(() => {
    if (!reviewsFetched) {
      getCustomerReviewCount(mafiaUrl, cookies, [ReviewStatusTypes.PASSED]).then((response: CloudReviewCount) => {
        setPassedSubmission(response.passed!);
      });
      setReviewsFetched(true);
    }
  }, [cookies, mafiaUrl, reviewsFetched]);

  return (
    <div className={cn(css.wrapper)}>
      <div className={css.reviewFormSuccess}>
        {
          <>
            <div className={css.lottieContainer}>
              {mediaOnly ? (
                <Loader />
              ) : (
                (passedSubmissions <= 1 && <ReviewConfirmationLlama loop={true} />) ||
                (passedSubmissions > 1 && <MultipleReviewsConfirmationLlama />)
              )}
            </div>
            <h1 className={css.header}>
              {mediaOnly ? ' ' : (passedSubmissions <= 1 && 'Awesome Post!') || (passedSubmissions > 1 && 'Review Master!')}
            </h1>
          </>
        }
        <div className={css.message}>
          <p data-test-id={testId('successfulReview')} className={css.paragraph}>
            {preambleText}
            <Link to="/orders" data-test-id={testId('orderHistory')}>
              your order history
            </Link>{' '}
            to write more reviews, or back to {productLink}.
          </p>
        </div>
      </div>
      {!mediaOnly && <ReviewYourPurchases productId={productId} />}
      <HappyFeatureFeedback
        additionalFeedbackMessage="Please tell us more about your experience"
        completionMessage="Thank you for your feedback!"
        feedbackQuestion={mediaOnly ? 'Please rate our outfit sharing process' : 'Please rate our review process'}
        feedbackType={mediaOnly ? MEDIA_UPLOAD_EXPERIENCE_FEEDBACK : PRODUCT_REVIEW_EXPERIENCE_FEEDBACK}
        pageType={mediaOnly ? MEDIA_UPLOAD_PAGE : WRITE_PRODUCT_REVIEW_PAGE}
        source={mediaOnly ? 'media-upload-confirmation' : 'review-confirmation'}
      />
    </div>
  );
};
export default AddReviewConfirmation;
