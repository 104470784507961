import type { ComponentProps, ComponentType } from 'react';
import React, { useState } from 'react';

import withJsonLdProduct from 'components/common/JsonLDProductWrapper';
import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import Link from 'components/hf/HFLink'; // Need to use dynamic link as MSFT products link externally
import Card from 'components/common/card/Card';
import CardMedia from 'components/common/card/CardMedia';
import CardPrice from 'components/common/card/CardPrice';
import useHover from 'hooks/useHover';

import styles from 'styles/components/common/productCardView.scss';

interface ProductCardViewProps {
  brandName: string;
  productName: string;
  url: string;
  styleId: string;
  linkLabel: string;
  color?: string;
  cardTestId?: string;
  className?: string;
  horizontal?: boolean;
  topOfImageRenderer?: ComponentType<ProductCardViewProps>;
  bottomOfImageRenderer?: ComponentType<ProductCardViewProps>;
  bottomOfDetailsRenderer?: ComponentType<ProductCardViewProps>;
  topOfDetailsRenderer?: ComponentType<ProductCardViewProps>;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => any;
  cardMediaProps: ComponentProps<typeof CardMedia>;
  cardPriceProps?: ComponentProps<typeof CardPrice>;
}

const ProductCardView = (props: ProductCardViewProps) => {
  const {
    className,
    brandName,
    onClick,
    productName,
    url,
    cardTestId,
    color,
    styleId,
    linkLabel,
    cardMediaProps,
    cardPriceProps,
    horizontal = false,
    topOfImageRenderer: TopOfImage,
    bottomOfImageRenderer: BottomOfImage,
    bottomOfDetailsRenderer: BottomOfDetails,
    topOfDetailsRenderer: TopOfDetails
  } = props;

  const { testId } = useMartyContext();
  const [ref, setRef] = useState(null);
  const { isHovered } = useHover(ref);

  return (
    <Card
      className={cn(className, { [styles.horizontalCard]: horizontal })}
      // For helping site merch collect styleIds https://github01.zappos.net/mweb/marty/pull/9699
      data-style-id={styleId}
      data-test-id={testId(cardTestId)}
      itemScope
      itemType="http://schema.org/Product"
    >
      <Link
        className={styles.linkOverlay}
        data-style-id={styleId}
        to={url}
        itemProp="url"
        data-test-id={testId('productCardLink')}
        onClick={onClick}
        innerRef={setRef}
      >
        {linkLabel}
      </Link>
      <CardMedia className={cn({ [styles.horizontalCardMedia]: horizontal })} isHovered={isHovered} {...cardMediaProps}>
        <div className={styles.top}>{TopOfImage && <TopOfImage {...props} />}</div>
        <div className={styles.bottom}>{BottomOfImage && <BottomOfImage {...props} />}</div>
      </CardMedia>
      <dl
        className={cn(styles.details, {
          [styles.horizontalDetails]: horizontal
        })}
      >
        {TopOfDetails && <TopOfDetails {...props} />}
        <dt>Brand Name</dt>
        <dd className={styles.mainText} itemProp="brand" itemScope itemType="http://schema.org/Brand" data-test-id={testId('brand')}>
          {brandName}
        </dd>
        <dt>Product Name</dt>
        <dd className={styles.subText} itemProp="name" data-test-id={testId('productName')}>
          {productName}
        </dd>
        {color && (
          <>
            <dt>Color</dt>
            <dd className={styles.colorName} itemProp="color" data-test-id={testId('colorName')}>
              {color}
            </dd>
          </>
        )}
        {cardPriceProps && <CardPrice {...cardPriceProps} />}
        {BottomOfDetails && <BottomOfDetails {...props} />}
      </dl>
    </Card>
  );
};

export default withJsonLdProduct(ProductCardView);
