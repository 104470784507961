import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { IMAGE, VIDEO } from 'constants/appConstants';
import type { LegacyContext } from 'types/context';

import css from 'styles/components/reviews/addReviewMediaPreview.scss';

interface AddReviewMediaPreviewProps {
  position?: number;
  src: string;
  type: string;
  onCloseClick: (x: number | undefined, y: string) => void;
}

class AddReviewMediaPreview extends Component<AddReviewMediaPreviewProps> {
  static contextTypes = {
    testId: PropTypes.func.isRequired
  };
  handleCloseClick = () => {
    const { position, type, onCloseClick } = this.props;
    onCloseClick(position, type);
  };

  render() {
    const { src, type } = this.props;
    const { testId } = this.context as LegacyContext;

    return (
      <div className={css.container} data-test-id={testId('addReviewMediaPreview')}>
        {type === IMAGE && <img className={css.mediaElement} src={src} alt="Upload Preview" data-test-id={testId('addReviewImagePreview')} />}
        {type === VIDEO && <video className={css.mediaElement} src={src} controls={true} data-test-id={testId('addReviewVideoPreview')} />}
        <button type="button" className={css.close} onClick={this.handleCloseClick} aria-label="Delete" data-test-id={testId('closeButton')}>
          ✕
        </button>
      </div>
    );
  }
}

export default AddReviewMediaPreview;
