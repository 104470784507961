import React from 'react';

import MartyLink from 'components/common/MartyLink';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';

import css from 'styles/components/influencer/influencerLinks.scss';

const InfluencerLinks = props => {
  const { slotDetails } = props;
  const { links } = slotDetails;
  const linkStyles = {
    basicPrimaryBtn: css.basicPrimaryBtn,
    basicBtn: css.basicBtn
  };

  const makeLink = link => {
    const { style, type, url, text, id } = link;
    return (
      <li key={id}>
        <MartyLink className={linkStyles[style]} to={url} type={type}>
          {text}
        </MartyLink>
      </li>
    );
  };

  return <ol className={css.influencerlinks}>{links.map(makeLink)}</ol>;
};
export default withErrorBoundary('InfluencerLinks', InfluencerLinks);
