import React, { Fragment } from 'react';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import useClientGuid from 'hooks/useClientGuid';

import css from 'styles/components/common/starRatingRadio.scss';

/*
  `starRatings` must be from highest to lowest for the CSS highlighting to work.

  Note: there are XBrowser bugs with fieldset & flexbox which is why I went with div[role='group']
  https://stackoverflow.com/questions/28078681/why-cant-fieldset-be-flex-containers
*/

type StarRatingRadioProps = {
  starRatings?: number[];
  indexId?: number;
  containerClassName?: string;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const StarRatingRadio = ({
  starRatings = [5, 4, 3, 2, 1],
  name = 'rating',
  indexId,
  containerClassName,
  ...inputAttributes
}: StarRatingRadioProps) => {
  const { testId } = useMartyContext();
  const guid = useClientGuid();
  return (
    <div role="group" className={cn(css.container, containerClassName)} data-test-id={testId('starRating')}>
      {starRatings.map(v => {
        const key = `${name}-star${v}-${guid}`;
        return (
          <Fragment key={key}>
            <input
              type="radio"
              name={name}
              id={key}
              value={v}
              data-star
              data-index-id={indexId} // Necessary for HmdSurvey
              {...inputAttributes}
            />
            <label aria-label={`${v} stars`} htmlFor={key} />{' '}
            {/* Use aria-label rather than hiding the text in the label because safari/ie still use the text in width calculations */}
          </Fragment>
        );
      })}
    </div>
  );
};

export default StarRatingRadio;
