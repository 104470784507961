import type { ChangeEventHandler } from 'react';
import React from 'react';
import PropTypes from 'prop-types';

import type useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/reviews/addReviewAbout.scss';

interface AddReviewAboutProps {
  reviewerName: string;
  reviewerLocation: string;
  otherShoes?: string;
  onAboutChange: ChangeEventHandler<HTMLInputElement>;
}

const AddReviewAbout = (props: AddReviewAboutProps, { testId }: Pick<ReturnType<typeof useMartyContext>, 'testId'>) => {
  const { otherShoes, onAboutChange, reviewerLocation, reviewerName } = props;

  return (
    <div>
      <label htmlFor="name" className={css.textInputLabel}>
        Your Name (Optional)
      </label>
      <input
        className={css.textInput}
        type="text"
        data-about="reviewerName"
        id="name"
        placeholder="First and Last Name"
        data-test-id={testId('reviewerName')}
        value={reviewerName}
        maxLength={100}
        onChange={onAboutChange}
      />
      <label htmlFor="location" className={css.textInputLabel}>
        Your City of Residence (Optional)
      </label>
      <input
        className={css.textInput}
        type="text"
        data-about="reviewerLocation"
        id="location"
        placeholder="Where Do You Live?"
        data-test-id={testId('reviewerLocation')}
        value={reviewerLocation}
        maxLength={100}
        onChange={onAboutChange}
      />
      <label htmlFor="otherShoes" className={css.textInputLabel}>
        What Other Brands Would You Recommend? (Optional)
      </label>
      <input
        type="text"
        className={css.textInput}
        data-about="otherShoes"
        id="otherShoes"
        placeholder="Let us know!"
        data-test-id={testId('reviewOtherInfoDesktop')}
        value={otherShoes}
        maxLength={100}
        onChange={onAboutChange}
      />
    </div>
  );
};

AddReviewAbout.propTypes = {
  reviewerName: PropTypes.string.isRequired,
  reviewerLocation: PropTypes.string.isRequired,
  otherShoes: PropTypes.string,
  onAboutChange: PropTypes.func.isRequired
};

AddReviewAbout.contextTypes = {
  testId: PropTypes.func.isRequired
};

export default AddReviewAbout;
