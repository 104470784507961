import React from 'react';

import type { InfluencerState, SocialMediaProfileRequestBody } from 'types/influencer';
import { InfluencerStatus, ProfileType } from 'types/influencer';
import SocialMediaCard from 'components/influencer/SocialMediaCard';
import { SocialMediaAppDetails } from 'constants/SocialMediaAppDetails';

import css from 'styles/containers/influencer/socialMediaCardList.scss';

interface SocialMediaCardListProps {
  influencer: InfluencerState;
  handleAddProfile: (requestBody: SocialMediaProfileRequestBody, isFirstEnroll: boolean) => void;
}
export const SocialMediaCardList = (props: SocialMediaCardListProps) => {
  const {
    influencer: {
      socialMediaProfiles,
      status,
      appConfig: { googleClientAppId, facebookClientAppId, InstagramClientAppId }
    },
    handleAddProfile
  } = props || {};
  const onProfileClick = (requestBody: SocialMediaProfileRequestBody) => {
    const isFirstEnroll = status !== InfluencerStatus.ACTIVE;
    handleAddProfile(requestBody, isFirstEnroll);
  };

  const makeSocialMediaCard = (profileType: ProfileType, clientId: Object) => {
    const profileDetails = socialMediaProfiles[profileType];
    const { imgSrc } = SocialMediaAppDetails[profileType];
    return (
      <div className={css.item}>
        <SocialMediaCard details={profileDetails} profileType={profileType} handleAddProfile={onProfileClick} imgSrc={imgSrc} clientId={clientId} />
      </div>
    );
  };

  return (
    <div className={css.container}>
      <div className={css.leftColumn}>
        {makeSocialMediaCard(ProfileType.INSTAGRAM, {
          app_id: InstagramClientAppId
        })}
        {makeSocialMediaCard(ProfileType.FACEBOOK, {
          client_id: facebookClientAppId
        })}
      </div>
      <div className={css.rightColumn}>
        {makeSocialMediaCard(ProfileType.YOUTUBE, {
          client_id: googleClientAppId
        })}
        {/* ToDo: Enable again after TikTok migration is completed in backend */}
        {/*{makeSocialMediaCard(ProfileType.TIKTOK, {*/}
        {/*  client_key: TikTokClientAppId*/}
        {/*})}*/}
      </div>
    </div>
  );
};
export default SocialMediaCardList;
