import React from 'react';

import { INFLUENCER_ENROLL_CALLBACK } from 'constants/influencerPages';
import { INFLUENCER_SIGNUP_METHOD_TYPE } from 'constants/amethystEnums';
import { track } from 'apis/amethyst';
import { evSignupInfluencer } from 'events/influencer';
import HtmlToReact from 'components/common/HtmlToReact';
import useMartyContext from 'hooks/useMartyContext';
import MartyLink from 'components/common/MartyLink';

import css from 'styles/components/influencer/infuencerCreateAccount.scss';

export const InfuencerCreateAccount = props => {
  const { testId } = useMartyContext();
  const {
    slotDetails: { heading, copy, createaccountcta, termsandpolicytext }
  } = props;
  const {
    marketplace: {
      features: {
        federatedLogin: { zapposRegisterUrl }
      }
    }
  } = useMartyContext();
  const returnTo = encodeURIComponent(INFLUENCER_ENROLL_CALLBACK);
  const tokenExchangeRedirect = false;

  const onSignupClick = e => {
    const { signupMethodType } = e.target.dataset;
    track(() => [evSignupInfluencer, { signupMethodType }]);
  };

  return (
    <section>
      <h2 data-test-id={testId('influencerCreateAccountHeading')} className={css.heading}>
        {heading}
      </h2>
      <p className={css.copy}>{copy}</p>
      <MartyLink
        data-test-id={testId('influencerCreateAccountLink')}
        to={`${zapposRegisterUrl}${returnTo}&tokenExchangeRedirect=${tokenExchangeRedirect}`}
        onClick={onSignupClick}
        data-signup-method-type={INFLUENCER_SIGNUP_METHOD_TYPE.CREATE_NEW_ACCOUNT}
        className={css.createAccount}
      >
        {createaccountcta}
      </MartyLink>

      <HtmlToReact data-test-id={testId('influencerCreateAccountPolicy')} className={css.terms}>
        {termsandpolicytext}
      </HtmlToReact>
    </section>
  );
};
export default InfuencerCreateAccount;
