import type { ChangeEvent, DragEvent } from 'react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { cn } from 'helpers/classnames';
import MelodyModal from 'components/common/MelodyModal';
import { AriaLiveTee } from 'components/common/AriaLive';
import { IMAGE, VIDEO } from 'constants/appConstants';
import { VALID_IMAGE_MIMETYPE, VALID_VIDEO_MIMETYPE } from 'common/regex';
import AddReviewMediaPreview from 'components/reviews/AddReviewMediaPreview';
import marketplace from 'cfg/marketplace.json';
import { onEvent } from 'helpers/EventHelpers';
import type { MediaSourceType } from 'types/amethyst';
import type { LegacyContext } from 'types/context';

import css from 'styles/components/reviews/addReviewMediaUpload.scss';

const {
  reviews: { allowedImageTypes, allowedVideoTypes }
} = marketplace;

const MAX_IMAGE_SIZE = 8000000; // 8MB
const MAX_VIDEO_SIZE = 50000000; // 50MB is the MSA limit

const FILE_SIZE_NOTE = 'Images must be less than 8MB in size while your video must be an mp4, less than 50MB, and one minute in length';
const INVALID_FILE_FORMAT_MESSAGE = 'Media must be either a video in mp4 format or an image in png or jpg format.';
const MAX_VIDEOS_UPLOADED_MESSAGE = 'The allotted space for videos has reached its max, so the file could not be added.';
const MAX_IMAGES_UPLOADED_MESSAGE = 'The allotted space for images has reached its max, so the file could not be added.';
const INVALID_IMAGE_SIZE_MESSAGE = 'Images must be no larger than 8MB.';
const INVALID_VIDEO_SIZE_MESSAGE = 'Videos must be no larger than 50MB.';
const VIDEO_ERROR_MESSAGE = 'The video was unable to be uploaded.';

interface AddReviewMediaUploadProps {
  images: string[];
  video: string;
  onMediaAttachment: (i: any, j?: MediaSourceType) => void;
  onAttachMediaClick: (i?: MediaSourceType) => void;
  outfitEncouragement?: boolean;
  required?: boolean;
  error?: string | undefined;
}

interface AddReviewMediaUploadState {
  isModalVisible: boolean;
  dragEventCount: number;
  errorMap: Record<string, string>;
}

const isValidImageMimeType = (type: string) => VALID_IMAGE_MIMETYPE.test(type);
const isValidVideoMimeType = (type: string) => VALID_VIDEO_MIMETYPE.test(type);
export const isValidMimeType = (type: string) => isValidImageMimeType(type) || isValidVideoMimeType(type);

export default class AddReviewMediaUpload extends Component<AddReviewMediaUploadProps> {
  static contextTypes = {
    testId: PropTypes.func.isRequired,
    marketplace: PropTypes.object
  };

  static defaultProps = {
    onAttachMediaClick: () => {}
  };

  state: AddReviewMediaUploadState = {
    isModalVisible: false,
    dragEventCount: 0,
    errorMap: {}
  };

  componentDidMount() {
    onEvent(document, 'dragover', this.onDragOverListener, undefined, this);
    onEvent(document, 'dragenter', this.onDragEnterListener, undefined, this);
    onEvent(document, 'dragleave', this.onDragLeaveListener, undefined, this);
    onEvent(document, 'drop', this.onDropListener, undefined, this);
  }

  reviewFormMediaDroppableArea: HTMLDivElement | null = null;
  imageLimit = 4;

  onCloseClick = (position = 0, type: string) => {
    const { images, onMediaAttachment } = this.props;
    if (type === IMAGE) {
      const newImages = [...images];
      newImages.splice(position, 1);
      onMediaAttachment({ images: newImages });
    } else {
      onMediaAttachment({ video: '' });
    }
  };

  onDragEnterListener = (event: DragEvent) => {
    event.preventDefault();
    const { dragEventCount } = this.state;
    this.setState({ dragEventCount: dragEventCount + 1 });
    if (this.reviewFormMediaDroppableArea) {
      this.reviewFormMediaDroppableArea.classList.add(css.show);
    }
  };

  onDragLeaveListener = () => {
    const { dragEventCount } = this.state;
    this.setState({ dragEventCount: dragEventCount - 1 });
    if (this.reviewFormMediaDroppableArea && dragEventCount === 0) {
      this.reviewFormMediaDroppableArea.classList.remove(css.show);
    }
  };

  onDragOverListener = (event: DragEvent) => {
    event.preventDefault();
  };

  onDropListener = (event: DragEvent) => {
    event.preventDefault();
    this.setState({ dragEventCount: 0, errorMap: {} });
    if (this.reviewFormMediaDroppableArea) {
      this.reviewFormMediaDroppableArea.classList.remove(css.show);
    }

    const { target } = event;

    if (target === this.reviewFormMediaDroppableArea) {
      const { files } = event.dataTransfer;

      if (files) {
        for (let i = 0; i < files.length; i++) {
          this.checkFileValidity(files[i]!);
        }
      }
    }
  };

  onFileUploadChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { files } = target;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        this.checkFileValidity(files[i]!);
      }
      target.value = '';
    }
  };

  onRequestClose = () => {
    this.setState({ isModalVisible: false });
  };

  getAcceptMimeType = () => allowedImageTypes.split(',').concat(allowedVideoTypes).join(',');

  addMessageToErrorModal = (filename: string, message: string) => {
    this.setState({
      isModalVisible: true,
      errorMap: { ...this.state.errorMap, [filename]: message }
    });
  };

  attemptToRenderImage = (
    imageUrl: string,
    uploadEventType: 'change' | 'drop',
    filename: string,
    { position, mediaSourceType }: { position?: number; mediaSourceType?: MediaSourceType } = {}
  ) => {
    if (uploadEventType === 'change') {
      this.renderSuccessfullyValidatedMedia(imageUrl, false, filename, {
        position,
        mediaSourceType
      });
    } else if (uploadEventType === 'drop') {
      this.renderSuccessfullyValidatedMedia(imageUrl, false, filename, {
        mediaSourceType
      });
    }
  };

  attemptToRenderVideo = (
    videoSrc: string,
    uploadEventType: 'change' | 'drop',
    filename: string,
    { position, mediaSourceType }: { position?: number; mediaSourceType?: MediaSourceType } = {}
  ) => {
    const video = document.createElement('video');
    video.src = videoSrc;
    video.oncanplay = () => {
      if (uploadEventType === 'change') {
        this.renderSuccessfullyValidatedMedia(videoSrc, true, filename, {
          position,
          mediaSourceType
        });
      } else if (uploadEventType === 'drop') {
        this.renderSuccessfullyValidatedMedia(videoSrc, true, filename, {
          mediaSourceType
        });
      }
    };
    video.onerror = () => {
      this.addMessageToErrorModal(filename, VIDEO_ERROR_MESSAGE);
    };
  };

  checkFileValidity = (file: File) => {
    if (isValidMimeType(file.type)) {
      if (isValidImageMimeType(file.type) && file.size > MAX_IMAGE_SIZE) {
        this.addMessageToErrorModal(file.name, INVALID_IMAGE_SIZE_MESSAGE);
      } else if (isValidVideoMimeType(file.type) && file.size > MAX_VIDEO_SIZE) {
        this.addMessageToErrorModal(file.name, INVALID_VIDEO_SIZE_MESSAGE);
      } else {
        this.processDroppedFile(file);
      }
    } else {
      this.addMessageToErrorModal(file.name, INVALID_FILE_FORMAT_MESSAGE);
    }
  };

  closeModal = () => {
    this.setState({ isModalVisible: false });
  };

  isEmptyImageSpaceClicked = (position: number, type: string) => {
    const { video, images } = this.props;
    return type === VIDEO ? !video : !images[position];
  };

  processDroppedFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target && event.target.result) {
        const result = event.target.result as string; // Since this would have been breaking if it were actually an Array Buffer we can assume it is a string
        const isVideo = result.indexOf('data:video/mp4;') === 0;

        if (isVideo) {
          this.attemptToRenderVideo(result, 'drop', file.name);
        } else {
          this.attemptToRenderImage(result, 'drop', file.name);
        }
      }
    };

    reader.readAsDataURL(file);
  };

  imageLimitReached = () => this.props.images.length >= this.imageLimit;
  videoLimitReached = () => !!this.props.video;

  uploadLimitReached = () => {
    const { imageLimitReached, videoLimitReached } = this;
    return imageLimitReached() && videoLimitReached();
  };

  renderSuccessfullyValidatedMedia = (
    encodedString: string,
    isVideo: boolean,
    filename: string,
    {
      position,
      mediaSourceType
    }: {
      position?: number;
      mediaSourceType?: MediaSourceType;
    } = {}
  ) => {
    const {
      props: { images, onMediaAttachment },
      imageLimitReached,
      videoLimitReached
    } = this;
    // Drag & Drop
    if (position === undefined) {
      if (isVideo && videoLimitReached()) {
        // There's already a video attached
        return this.addMessageToErrorModal(filename, MAX_VIDEOS_UPLOADED_MESSAGE);
      } else if (!isVideo && imageLimitReached()) {
        // All image slots are already attached
        return this.addMessageToErrorModal(filename, MAX_IMAGES_UPLOADED_MESSAGE);
      }

      // Find the next available position
      position = images.length;
    }

    if (isVideo) {
      onMediaAttachment({ video: encodedString }, mediaSourceType);
    } else {
      const newImages = images.slice();
      newImages[position] = encodedString;
      onMediaAttachment({ images: newImages }, mediaSourceType);
    }
  };

  addImages = (urls: string[], mediaSourceType?: MediaSourceType) => {
    const { images, onMediaAttachment } = this.props;
    onMediaAttachment({ images: [...images, ...urls] }, mediaSourceType);
  };

  makeLabelContent = () => {
    const { outfitEncouragement } = this.props;

    if (outfitEncouragement) {
      return (
        <>
          <span className={css.mediaUploadButtonHeadline}>Drag and drop photos here</span>
          <span className={css.mediaUploadLine}>or</span>
          <span aria-hidden={true} className={css.fakeButton}>
            Browse Files
          </span>
          <span className={css.mediaUploadLine}>add up to four images and/or one video.</span>
          {this.makeMediaUploadNote()}
        </>
      );
    }

    return 'Attach Media';
  };

  makeLabel = () => {
    const { outfitEncouragement } = this.props;
    const { testId } = this.context as LegacyContext;
    const isUploadLimitReached = this.uploadLimitReached();

    return (
      <label
        htmlFor={isUploadLimitReached ? '#' : 'fileUpload'}
        className={cn(css.mediaUploadButton, {
          [css.outfitEncouragementMediaUploadButton]: outfitEncouragement
        })}
        data-test-id={testId('addReviewMediaUploadLabel')}
      >
        {this.makeLabelContent()}
      </label>
    );
  };

  makeMediaUploadNote = () => <p className={css.note}>(Note: {FILE_SIZE_NOTE})</p>;

  render() {
    const { errorMap, isModalVisible } = this.state;
    const { images, video, error, onAttachMediaClick, outfitEncouragement, required } = this.props;
    const shouldDisplayAttachImageBoxesOnMobile = images.some(Boolean) || video;
    const isUploadLimitReached = this.uploadLimitReached();
    const { testId } = this.context as LegacyContext;

    return (
      <div>
        {!outfitEncouragement && this.makeMediaUploadNote()}
        {error && (
          <AriaLiveTee>
            <div className={css.error} data-test-id={testId('error')}>
              {error}
            </div>
          </AriaLiveTee>
        )}
        <div className={css.reviewFormMedia}>
          <fieldset className={css.reviewFormMediaFieldset}>
            <div
              className={cn(css.attachImageBoxes, {
                [css.visible]: shouldDisplayAttachImageBoxesOnMobile
              })}
            >
              {images.map((image, index) => (
                <AddReviewMediaPreview key={index} position={index} type={IMAGE} src={image} onCloseClick={this.onCloseClick} />
              ))}
              {video && <AddReviewMediaPreview type={VIDEO} src={video} onCloseClick={this.onCloseClick} />}
            </div>
          </fieldset>
          <div className={css.reviewFormMediaDroppableArea} ref={el => (this.reviewFormMediaDroppableArea = el)}>
            <p className={css.reviewFormMediaHoverText}>Drop Here to Attach!</p>
          </div>
          <div>
            <input
              type="file"
              id="fileUpload"
              disabled={isUploadLimitReached}
              className={css.mediaUploadInput}
              data-test-id={testId('addReviewMediaUploadInput')}
              onChange={this.onFileUploadChange}
              onClick={() => onAttachMediaClick()}
              accept={this.getAcceptMimeType()}
              required={required}
              multiple
            />
            {this.makeLabel()}
          </div>
        </div>
        <MelodyModal
          isOpen={isModalVisible}
          onRequestClose={this.onRequestClose}
          className={css.modalContent}
          heading="Oops, please fix the issues below"
          headingClassName={css.errorModalTitle}
        >
          {Object.keys(errorMap).map((key, index) => (
            <dl key={index}>
              <dt className={css.errorFileName}>{key}</dt>
              <dd className={css.errorFileDescription}>{errorMap[key]}</dd>
            </dl>
          ))}
        </MelodyModal>
      </div>
    );
  }
}
