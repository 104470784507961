import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import css from 'styles/components/checkout/typ/contactUs.scss';

const ContactUs = (
  undefined,
  {
    testId,
    marketplace: {
      checkout: {
        typ: { contactUsVerbiage }
      }
    }
  }
) => (
  <div className={css.container} data-test-id={testId('contactUsSection')}>
    <div>Have questions?</div>
    <div>{contactUsVerbiage}</div>
    <div>
      You can also visit our help pages or{' '}
      <Link to="/c/contact-us" className={css.contactUsLink}>
        Contact Us
      </Link>
    </div>
  </div>
);

ContactUs.contextTypes = {
  testId: PropTypes.func,
  marketplace: PropTypes.object
};

export default ContactUs;
