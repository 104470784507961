export interface ReviewData {
  productId: string;
  colorId?: string;
  incentivized?: boolean;
  premier?: boolean;
  type?: string;
}

export interface ReviewMedia {
  // base64 representations
  images: string[];
  video: string;
}

export interface CloudReviewParams {
  productId: string;
  images?: string[]; // base64 encoded image strings
}

export interface CloudReviewProductSummary {
  productId: string;
  reviewCount: number;
  avgOverallRating: number | null;
  avgComfortRating: number | null;
  avgLookRating: number | null;
}

export interface CloudReviewCount {
  passed?: number;
  submitted?: number;
}

export enum ReviewStatusTypes {
  PASSED = 'passed',
  SUBMITTED = 'submitted',
  CENSORED = 'censored',
  FLAGGED = 'flagged',
  PI_FLAG = 'pii_flag'
}

export interface CloudReviewError {
  timestamp: number;
  status: number;
  error: string;
  exception: string;
  message: string;
  path: string;
}
