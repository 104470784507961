import { useCallback, useReducer, useRef } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';

import { cn } from 'helpers/classnames';
import { handleUpdateInfluencerDetails, handleValidateProfileHandle } from 'actions/influencer/influencer';
import { HtmlToReact } from 'components/common/HtmlToReact';
import { ALPHA_NUMERIC_DASH_DOT } from 'common/regex';

import css from 'styles/components/influencer/influencerEnrollFormV2.scss';

const MAX_INPUT_LENGTH = 30;

export const InfluencerEnrollFormV2 = props => {
  const {
    slotDetails: { ctaText, termsAndPolicyText, textBoxFooter, textBoxPlaceholder, textBoxTitle },
    handleUpdateInfluencerDetails,
    handleValidateProfileHandle
  } = props;
  const usernameInputRef = useRef(null);

  const initialState = {
    showSuccessBox: false,
    isValid: true,
    isUnique: true,
    inputLength: 0
  };
  const reducer = (state, action) => ({ ...state, ...action });
  const [state, dispatch] = useReducer(reducer, initialState);
  const { showSuccessBox, isValid, isUnique, inputLength } = state;

  const validator = useCallback(
    debounce(profileHandle => handleValidateProfileHandle(profileHandle).then(isUnique => dispatch({ isUnique, showSuccessBox: isUnique })), 500),
    []
  );

  const handleSubmit = event => {
    event.preventDefault();
    const {
      current: { value: profileHandle }
    } = usernameInputRef;
    handleValidateProfileHandle(profileHandle).then(isUnique => {
      isUnique ? handleUpdateInfluencerDetails({ profileHandle }) : dispatch({ isUnique, showSuccessBox: false });
    });
  };

  const handleChange = event => {
    event.preventDefault();
    const {
      current: { value: profileHandle }
    } = usernameInputRef;
    const inputLength = profileHandle?.length;
    const isValid = inputLength > 0 && inputLength < MAX_INPUT_LENGTH && ALPHA_NUMERIC_DASH_DOT.test(profileHandle);
    dispatch({ inputLength, isValid, isUnique: true, showSuccessBox: false });

    if (profileHandle && isValid) {
      validator(profileHandle);
      return () => validator.cancel();
    }
  };

  const getWarningCopy = () => {
    const profileHandle = usernameInputRef?.current?.value;
    if (!ALPHA_NUMERIC_DASH_DOT.test(profileHandle)) {
      return 'Please use only small case letters (a-z), numbers, "." and "-".';
    }
    if (!profileHandle || profileHandle.length >= MAX_INPUT_LENGTH) {
      return;
    }
    if (!isUnique) {
      return 'Sorry, ' + profileHandle + ' is taken. Please choose another Username!';
    }
    if (showSuccessBox) {
      return profileHandle + ' is good to go!';
    }
  };

  const isValidAndUnique = isValid && isUnique;
  return (
    <form method="post" onSubmit={handleSubmit} className={css.nameForm}>
      <label htmlFor="influencerHandle" className={css.title}>
        {textBoxTitle}
      </label>
      <div
        className={cn(css.nameInput, {
          [css.successBox]: isValidAndUnique && showSuccessBox,
          [css.errorBox]: !isValidAndUnique
        })}
      >
        <input
          id="influencerHandle"
          required
          type="text"
          maxLength={MAX_INPUT_LENGTH}
          placeholder={textBoxPlaceholder}
          ref={usernameInputRef}
          onChange={handleChange}
          aria-describedby="influencerHandleFooter"
        />
        <div id="influencerHandleLength" className={css.inputLength}>
          {Math.min(inputLength, 30)}/30
        </div>
        <div id="influencerHandleWarning" className={css.inputWarning}>
          {getWarningCopy()}
        </div>
        {isValidAndUnique && showSuccessBox && (
          <div className={css.profileURL}>
            Your profile URL is&nbsp;
            <b>
              <i>zappos.com/influencer/profile/{usernameInputRef?.current?.value}</i>
            </b>
          </div>
        )}
        <small id="influencerHandleFooter" className={css.footerCopy}>
          {textBoxFooter}
        </small>
      </div>
      <div className={css.terms}>
        <input type="checkbox" id="influencerTerms" required />
        <label htmlFor="influencerTerms">
          <HtmlToReact noContainer={true}>{termsAndPolicyText}</HtmlToReact>
        </label>
      </div>
      <button type="submit" disabled={!isValidAndUnique}>
        {ctaText}
      </button>
    </form>
  );
};

const connector = connect(null, {
  handleUpdateInfluencerDetails,
  handleValidateProfileHandle
});
export default connector(InfluencerEnrollFormV2);
