import type { ChangeEvent } from 'react';
import React from 'react';
import PropTypes from 'prop-types';

import { cn } from 'helpers/classnames';
import type useMartyContext from 'hooks/useMartyContext';

import styles from 'styles/components/reviews/addReviewFitting.scss';

interface AddReviewFittingProps {
  shoeSize: string;
  shoeWidth: string;
  shoeArch: string;
  onFitChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const AddReviewFitting = (
  { shoeSize, shoeWidth, shoeArch, onFitChange }: AddReviewFittingProps,
  { testId }: Pick<ReturnType<typeof useMartyContext>, 'testId'>
) => (
  <div className={styles.fitContainer}>
    <div className={styles.selectField}>
      <label className={styles.label} htmlFor="shoeSize">
        Size
      </label>
      <select
        id="shoeSize"
        className={styles.select}
        data-fitting="shoeSize"
        data-test-id={testId('shoeSize')}
        defaultValue={shoeSize}
        onChange={onFitChange}
        required
      >
        <option value="ONE_SIZE_LARGER" data-test-id={testId('sizeOneLarger')}>
          Felt full size larger
        </option>
        <option value="HALF_SIZE_LARGER" data-test-id={testId('sizeHalfLarger')}>
          Felt half size larger
        </option>
        <option value="TRUE_TO_SIZE" data-test-id={testId('trueToSize')}>
          Felt true to size
        </option>
        <option value="HALF_SIZE_SMALLER" data-test-id={testId('sizeHalfSmaller')}>
          Felt full size smaller
        </option>
        <option value="FULL_SIZE_SMALLER" data-test-id={testId('sizeOneSmaller')}>
          Felt half size smaller
        </option>
      </select>
    </div>
    <div className={styles.selectField}>
      <label className={styles.label} htmlFor="shoeWidth">
        Width
      </label>
      <select
        id="shoeWidth"
        className={styles.select}
        data-fitting="shoeWidth"
        data-test-id={testId('shoeWidth')}
        defaultValue={shoeWidth}
        onChange={onFitChange}
        required
      >
        <option value="WIDER" data-test-id={testId('widerOption')}>
          Felt wider than marked
        </option>
        <option value="TRUE" data-test-id={testId('trueToWidth')}>
          Felt true to width
        </option>
        <option value="NARROWER" data-test-id={testId('narrowOption')}>
          Felt narrower than marked
        </option>
      </select>
    </div>
    <div className={cn(styles.selectField, styles.archField)}>
      <label className={styles.label} htmlFor="shoeArch">
        Support
      </label>
      <select
        id="shoeArch"
        className={styles.select}
        data-fitting="shoeArch"
        data-test-id={testId('shoeArch')}
        defaultValue={shoeArch}
        onChange={onFitChange}
        required
      >
        <option value="EXCELLENT" data-test-id={testId('excellentArchSupport')}>
          Excellent arch support
        </option>
        <option value="MODERATE" data-test-id={testId('modArchSupport')}>
          Moderate arch support
        </option>
        <option value="NONE" data-test-id={testId('noArchSupport')}>
          No arch support
        </option>
      </select>
    </div>
  </div>
);

AddReviewFitting.propTypes = {
  shoeSize: PropTypes.string.isRequired,
  shoeWidth: PropTypes.string.isRequired,
  shoeArch: PropTypes.string.isRequired,
  onFitChange: PropTypes.func.isRequired
};

AddReviewFitting.contextTypes = {
  testId: PropTypes.func
};

export default AddReviewFitting;
