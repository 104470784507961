import React from 'react';

import HtmlToReact from 'components/common/HtmlToReact';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/influencer/influencerFooter.scss';

const InfluencerFooter = props => {
  const { testId } = useMartyContext();
  const CURRENT_YEAR = new Date().getFullYear();
  const { ['primary-19']: { termsAndPolicyText } = {}, ['primary-20']: copyright } = props.slotData || [];

  if (!copyright) {
    return null;
  }

  return (
    <footer className={css.footer}>
      <HtmlToReact data-test-id={testId('influencerFooterPolicy')}>{termsAndPolicyText}</HtmlToReact>
      <HtmlToReact data-test-id={testId('influencerFooterPageContent')}>
        {copyright.pageContent?.body.replace(/{{currentYear}}/g, CURRENT_YEAR)}
      </HtmlToReact>
    </footer>
  );
};
export default InfluencerFooter;
