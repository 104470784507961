import { ProfileType } from 'types/influencer';
import instagram from 'images/zappos/instagram.svg';
import facebook from 'images/zappos/facebook.svg';
import youtube from 'images/zappos/youtube.svg';
import tiktok from 'images/zappos/tiktok.svg';
export const SocialMediaAppDetails = {
  Facebook: {
    params: {
      scope: 'email public_profile',
      state: ProfileType.FACEBOOK
    },
    oAuthUrl: 'https://www.facebook.com/v10.0/dialog/oauth',
    ariaLabel: 'Apply through Facebook',
    imgSrc: facebook
  },
  Instagram: {
    params: {
      scope: 'user_profile',
      response_type: 'code',
      state: ProfileType.INSTAGRAM
    },
    oAuthUrl: 'https://api.instagram.com/oauth/authorize/',
    ariaLabel: 'Apply through Instagram',
    imgSrc: instagram
  },
  Youtube: {
    params: {
      scope: 'openid profile email https://www.googleapis.com/auth/youtube.readonly',
      response_type: 'code',
      state: ProfileType.YOUTUBE,
      access_type: 'offline',
      approval_prompt: 'force'
    },
    imgSrc: youtube,
    oAuthUrl: 'https://accounts.google.com/o/oauth2/auth/oauthchooseaccount',
    ariaLabel: 'Apply through Youtube'
  },
  TikTok: {
    params: {
      scope: 'user.info.basic',
      response_type: 'code',
      state: ProfileType.TIKTOK
    },
    oAuthUrl: 'https://open-api.tiktok.com/platform/oauth/connect/',
    ariaLabel: 'Apply through Tiktok',
    imgSrc: tiktok
  }
};
