import React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { cn } from 'helpers/classnames';
import StarRatingRadio from 'components/common/StarRatingRadio';
import { keyBy } from 'helpers/lodashReplacement';
import { getProductReviewSummary } from 'apis/cloudreviews';
import ProductCardView from 'components/common/card/ProductCardView';
import type { AppState } from 'types/app';
import type { CloudReviewProductSummary } from 'types/cloudReviews';
import { constructMSAImageUrl } from 'helpers/index';
import useApi from 'hooks/useApi';
import useMartyContext from 'hooks/useMartyContext';
import { evWriteProductReviewClick } from 'events/productReview';
import { WRITE_PRODUCT_REVIEW_PAGE } from 'constants/amethystPageTypes';
import { ZAPPOS_MERCHANTID } from 'constants/crossSiteMerchantIdMapping';
import { selectMafiaConfig } from 'selectors/environment';

import css from 'styles/components/reviews/reviewYourPurchases.scss';

const NUMBER_OF_PRODUCTS = 6;

const msaOpts = {
  height: 500,
  width: 500,
  autoCrop: true
};

export const FirstToReviewBadge = ({ hidden = true }) => (
  <div className={cn(css.firstToReview, { [css.hidden]: hidden })}>Be the first to review!</div>
);

type OwnProps = { productId: string };
type Props = ConnectedProps<typeof connector> & OwnProps;
const ReviewYourPurchases = ({ cookies, products, mafiaUrl }: Props) => {
  const { amethystTrack, router, testId } = useMartyContext();
  const productIdListString = products.map(({ product: { productId } }) => productId).join(',');
  const { isLoaded, data } = useApi(
    getProductReviewSummary,
    [mafiaUrl, cookies, productIdListString],
    (...[, , idListFromParams]) => Boolean(idListFromParams) && idListFromParams.length > 0
  );
  if (products.length === 0) {
    return null;
  }
  const reviewSummaryMap = isLoaded && data ? (keyBy(data, 'productId') as Record<string, CloudReviewProductSummary | undefined>) : {};
  return (
    <>
      <h2>Rate Past Purchases</h2>
      <p>Your review helps other customers decide if a product is right for them</p>
      <div className={css.wrapper} data-test-id={testId('reviewYourPurchases')}>
        {products && (
          <div className={css.productCards}>
            {' '}
            {products.map(({ id: lineItemId, product: { asin, styleId, attributes, imageId, productId, brand, name, reviewUrl, stockId } }) => {
              const reviewSummary = reviewSummaryMap[productId];
              const cardMediaProps = {
                mainImage: {
                  src: constructMSAImageUrl(imageId, msaOpts)
                }
              };
              const amethystReviewClick = () => [
                evWriteProductReviewClick,
                {
                  productId,
                  styleId,
                  stockId,
                  asin,
                  addedFrom: WRITE_PRODUCT_REVIEW_PAGE
                }
              ];
              return (
                <ProductCardView
                  className={css.productCard}
                  key={lineItemId}
                  brandName={brand}
                  productName={name}
                  linkLabel="Write A Review"
                  url={reviewUrl}
                  color={attributes.find(({ key }) => key === 'Color')?.value}
                  styleId={styleId}
                  cardMediaProps={cardMediaProps}
                  horizontal={true}
                  onClick={() => void amethystTrack(amethystReviewClick)}
                  bottomOfDetailsRenderer={() => (
                    <>
                      <StarRatingRadio
                        containerClassName={css.stars}
                        name={productId}
                        onChange={e => {
                          router.pushPreserveAppRoot(`${reviewUrl}?rating=${e.target.value}`);
                          amethystTrack(amethystReviewClick);
                        }}
                      />
                      <FirstToReviewBadge hidden={!reviewSummary || reviewSummary.reviewCount !== 0} />
                    </>
                  )}
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

const connector = connect((state: AppState, props: OwnProps) => {
  const { cookies } = state;
  const mafiaConfig = selectMafiaConfig(state);
  const products = reviewYourPurchasesSelector(state, props);
  return {
    products,
    mafiaUrl: mafiaConfig.url,
    cookies
  };
});

export default connector(ReviewYourPurchases);

const reviewYourPurchasesSelector = createSelector(
  [(state: AppState) => state.orders, (_state: AppState, props: OwnProps) => props],
  ({ orders }, { productId }) =>
    (orders &&
      orders
        .flatMap(({ lineItems }) => lineItems) // flatten lineItems from orders reducer
        .filter(({ merchantId }) => merchantId === ZAPPOS_MERCHANTID) // only allow reviews for Zappos line items
        .filter(({ reviewed }) => !reviewed) // filter already reviewed items
        .filter(({ product: { reviewUrl } }) => Boolean(reviewUrl)) // filter already reviewed items
        .filter(({ product }) => product.productId !== productId) // filter product that was just reviewed
        .filter(({ product }, index, products) => products.findIndex(({ product: { productId } }) => productId === product.productId) === index) // remove duplicate products
        .slice(0, NUMBER_OF_PRODUCTS)) ||
    [] // slice to only NUMBER_OF_PRODUCTS
);
