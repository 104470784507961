import React from 'react';

import useMartyContext from 'hooks/useMartyContext';
import HtmlToReact from 'components/common/HtmlToReact';
import { stripSpecialChars } from 'helpers/index';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';

import css from 'styles/components/influencer/influencerFaqs.scss';

const InfluencerFaqs = props => {
  const { testId } = useMartyContext();
  const { slotDetails } = props;
  const { faqSections } = slotDetails;

  return (
    <div className={css.faqs}>
      <h2>Frequently Asked Questions</h2>
      <div className={css.faqContent}>
        {faqSections.map((faqSection, index) => {
          const { heading, faqs } = faqSection;
          return (
            <dl key={`${stripSpecialChars(heading)}_${index}`} className={css.faq}>
              {faqs.map(faq => (
                <React.Fragment key={faq.id}>
                  <dt data-test-id={testId('influencerFaqsQuestion')}>
                    <HtmlToReact noContainer={true}>{faq.question}</HtmlToReact>
                  </dt>
                  <dd data-test-id={testId('influencerFaqsAnswer')}>
                    <HtmlToReact noContainer={true}>{faq.answer}</HtmlToReact>
                  </dd>
                </React.Fragment>
              ))}
            </dl>
          );
        })}
      </div>
    </div>
  );
};
export default withErrorBoundary('InfluencerFaqs', InfluencerFaqs);
