import React, { Component } from 'react';
import PropTypes from 'prop-types';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { Store } from 'redux';
import type { RouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { isRecognized } from 'helpers/AuthHelpers';
import { buildSeoProductUrl } from 'helpers/SeoUrlBuilder';
import { getWriteReviewReturnUrl } from 'helpers/ReviewUtils';
import { redirectToAuthenticationFor } from 'actions/redirect';
import {
  addReview as addCloudReview,
  addFieldValidationErrors,
  loadWriteReviewPage,
  resetAddReview,
  setWriteReviewDocMeta,
  updateAbout,
  updateFit,
  updateRating,
  updateSummary
} from 'actions/addReview';
import { fetchOrders } from 'actions/account/orders';
import { PageLoader } from 'components/Loader';
import LandingSlot from 'containers/LandingSlot.jsx';
import SiteAwareMetadata from 'components/SiteAwareMetadata';
import AddReviewForm from 'components/reviews/AddReviewForm';
import AddReviewConfirmation from 'components/reviews/AddReviewConfirmation';
import { fetchLandingPageInfo, resetLandingPageInfo } from 'actions/landing/landingPageInfo';
import { titaniteView, track } from 'apis/amethyst';
import { evSubmitMediaUploadClick, evSubmitProductReviewClick, pvMediaUpload, pvWriteProductReview } from 'events/productReview';
import Breadcrumbs from 'components/common/Breadcrumbs';
import { MartyContext } from 'utils/context';
import type { AppState } from 'types/app';
import type { ReviewData, ReviewMedia } from 'types/cloudReviews';

import css from 'styles/containers/addReview.scss';

const defaultProps = {
  track
};

type Params = { productId: string; colorId: string; p: string };
type Query = { rating?: string };
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & RouteComponentProps<Params, any, Query> & typeof defaultProps;

export class AddReview extends Component<Props> {
  static fetchDataOnServer(store: Store, location: Location, params: Params) {
    if (isRecognized(store)) {
      return Promise.all([
        store.dispatch(
          loadWriteReviewPage(params, {
            errorOnOos: false,
            mediaOnly: /\/add\/media\//.test(location.pathname)
          }) as any
        ),
        store.dispatch(fetchLandingPageInfo('review-content') as any)
      ]);
    } else {
      return Promise.resolve(store.dispatch(redirectToAuthenticationFor(location) as any));
    }
  }

  static contextTypes = {
    router: PropTypes.object.isRequired
  };

  static defaultProps = defaultProps;

  componentDidMount() {
    const {
      params: { productId, colorId, p: isPremier },
      product: { detail },
      orders,
      ordersLoading,
      loadWriteReviewPage,
      location,
      isRecognized,
      redirectToAuthenticationFor,
      setWriteReviewDocMeta,
      slotDataLoaded,
      fetchLandingPageInfo,
      resetLandingPageInfo,
      fetchOrders
    } = this.props;

    const context = this.context as React.ContextType<typeof MartyContext>;

    resetLandingPageInfo();

    // if we client routed here, check if they're recognized
    if (isRecognized) {
      titaniteView();
      const productChanged = !detail || detail.productId !== productId;
      const productIdUrl = getWriteReviewReturnUrl(productId, colorId);
      const mediaOnly = this.isMediaOnly();
      if (productChanged) {
        loadWriteReviewPage(productId, { errorOnOos: false, mediaOnly });
      } else if (detail) {
        // Set the title for pdp->write review and order history -> /createReview/:asin -> /product/review/add/:productId cases
        setWriteReviewDocMeta(detail, mediaOnly);
      }

      this.redirectToPdpIfMediaOnlyAndNotReviewableWithMedia();

      // If we came in via the /asin/:asin route, replace this to the correct URL and preserve search params
      if (!productChanged && !isPremier && window.location.pathname.includes('/asin/')) {
        context.router.replacePreserveAppRoot(`${productIdUrl}${window.location.search}`);
      }

      if (!slotDataLoaded) {
        fetchLandingPageInfo('review-content');
      }

      // fetch orders on mount and fail silently
      if (!ordersLoading && !orders) {
        fetchOrders({ pageSize: 20 }, { failSilently: true });
      }

      this.trackPageView();
    } else {
      redirectToAuthenticationFor(location, this.getPdpUrl());
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      addReview: { isSubmitted: prevIsSubmitted },
      loadWriteReviewPage,
      params: { productId: prevProductId },
      product: prevProduct
    } = prevProps;
    const {
      addReview: { isSubmitted },
      params: { productId },
      product,
      ordersLoading,
      fetchOrders
    } = this.props;
    const productIdChanged = productId !== prevProductId;
    if (productIdChanged) {
      loadWriteReviewPage(productId, {
        errorOnOos: false,
        mediaOnly: this.isMediaOnly()
      });
      // refetch orders when a new product is being reviewed to keep an up to date record of what was previously reviewed
      if (!ordersLoading) {
        fetchOrders({ pageSize: 20 }, { failSilently: true });
      }
    }

    if (prevIsSubmitted !== isSubmitted && isSubmitted) {
      window.scrollTo(0, 0);
    }
    if (prevProduct?.detail?.productId !== product?.detail?.productId) {
      // loaded product changed
      this.redirectToPdpIfMediaOnlyAndNotReviewableWithMedia();
    }
  }

  componentWillUnmount() {
    const { resetAddReview } = this.props;
    resetAddReview();
  }

  getPdpUrl = () => {
    const {
      params: { productId, colorId }
    } = this.props;
    return `/product/${productId}${colorId ? `/color/${colorId}` : ''}`;
  };

  redirectToPdpIfMediaOnlyAndNotReviewableWithMedia = () => {
    const {
      product: { detail }
    } = this.props;
    const context = this.context as React.ContextType<typeof MartyContext>;

    if (detail && !detail.isReviewableWithMedia && this.isMediaOnly()) {
      // can't do media-only upload for this product, so go back to pdp
      // replace instead of push so the user can't go back to the add media page.
      context.router.replacePreserveAppRoot(this.getPdpUrl());
    }
  };

  trackPageView = () => {
    const {
      params: { productId, colorId },
      track
    } = this.props;
    const pageViewEvent = this.isMediaOnly() ? pvMediaUpload : pvWriteProductReview;
    track(() => [pageViewEvent, { productId, colorId }]);
  };

  makeSubmitReviewErrorMessage = () => {
    const { submitReviewErrorMessage } = this.props.addReview;
    return <div className={css.formError}>{submitReviewErrorMessage}</div>;
  };

  handleSubmitReview = (data: { reviewData: ReviewData; reviewMedia?: ReviewMedia; returnTo?: string }) => {
    const { addCloudReview } = this.props;
    return addCloudReview(data).then(review => {
      if (review) {
        this.trackSubmitClick({
          reviewId: review.reviewId,
          incomplete: !review.reviewId
        });
      }
    });
  };

  handleValidationError = (data: Record<string, boolean>) => {
    const { addFieldValidationErrors } = this.props;
    addFieldValidationErrors(data);
    this.trackSubmitClick({ incomplete: true });
  };

  trackSubmitClick = ({ reviewId, incomplete }: { reviewId?: string; incomplete?: boolean }) => {
    const {
      params: { productId, colorId },
      track
    } = this.props;
    const submitEvent = this.isMediaOnly() ? evSubmitMediaUploadClick : evSubmitProductReviewClick;
    track(() => [
      submitEvent,
      {
        productId,
        colorId,
        reviewId,
        incomplete
      }
    ]);
  };

  makeProductLink(linkTestId?: string) {
    const {
      params: { colorId },
      product: { detail }
    } = this.props;
    if (detail) {
      const { brandName, productName } = detail;
      return (
        <MartyContext.Consumer>
          {({ testId }) => (
            <Link to={buildSeoProductUrl(detail, colorId)} data-test-id={testId(linkTestId)}>
              {brandName} {productName}
            </Link>
          )}
        </MartyContext.Consumer>
      );
    } else {
      return;
    }
  }

  makeHeading() {
    return <h1 className={css.heading}>{this.isMediaOnly() ? 'Add Your Outfit' : <>Write a Review {this.makeProductLink()}</>}</h1>;
  }

  isMediaOnly = () => this.props.path?.includes('media') ?? false;

  render() {
    const {
      props: {
        addReview: { isSubmitted, submitReviewErrorMessage },
        params: { productId, colorId, p: isPremier },
        product: { detail, isLoading: isLoadingProduct },
        addReview,
        params,
        isRecognized,
        updateSummary,
        updateRating,
        updateFit,
        updateAbout,
        slotData,
        location
      },
      makeSubmitReviewErrorMessage
    } = this;
    if (!detail || !isRecognized || isLoadingProduct) {
      return <PageLoader />;
    } else if (isSubmitted && !submitReviewErrorMessage) {
      return (
        <SiteAwareMetadata>
          <AddReviewConfirmation
            productId={productId}
            mediaOnly={this.isMediaOnly()}
            productLink={this.makeProductLink('confirmationProductLink')}
          />
        </SiteAwareMetadata>
      );
    } else {
      const breadcrumbs = [
        {
          to: buildSeoProductUrl(detail, colorId),
          text: `${detail.brandName} ${detail.productName}`
        },
        { to: `/product/review/${productId}`, text: 'Review Summary' }
      ];
      return (
        <SiteAwareMetadata>
          <LandingSlot data={slotData['primary-1']} />
          <div className={css.wrap}>
            <Breadcrumbs links={breadcrumbs} currentText="Write A Review" />
            {this.makeHeading()}
            {submitReviewErrorMessage && makeSubmitReviewErrorMessage()}
            <AddReviewForm
              addReview={addReview}
              product={detail}
              params={params}
              returnTo={getWriteReviewReturnUrl(productId, colorId, Boolean(isPremier))}
              mediaOnly={this.isMediaOnly()}
              onUpdateSummary={updateSummary}
              onUpdateRating={updateRating}
              onUpdateFit={updateFit}
              onUpdateAbout={updateAbout}
              onSubmitProductReview={this.handleSubmitReview}
              onAddFieldValidationErrors={this.handleValidationError}
              productLink={this.makeProductLink()}
              boolMediaUpload={true}
              initialStarRating={new URLSearchParams(location?.search).get('rating') ?? ''}
            />
          </div>
        </SiteAwareMetadata>
      );
    }
  }
}

export function mapStateToProps(state: AppState, ownProps: any = {}) {
  const {
    orders: { orders, isLoading },
    cookies,
    product,
    reviews: { addReview },
    landingPage: { isLoaded, pageInfo: { slotData = {} } = {} }
  } = state;
  const { inParams, match: { params = {}, path = '' } = {} } = ownProps || {};
  return {
    params: inParams ?? params,
    path,
    orders,
    ordersLoading: isLoading,
    product,
    addReview,
    isRecognized: isRecognized(cookies),
    slotData,
    slotDataLoaded: isLoaded
  };
}

const mapDispatchToProps = {
  addCloudReview,
  addFieldValidationErrors,
  loadWriteReviewPage,
  redirectToAuthenticationFor,
  resetAddReview,
  updateSummary,
  updateRating,
  updateFit,
  updateAbout,
  fetchLandingPageInfo,
  resetLandingPageInfo,
  setWriteReviewDocMeta,
  fetchOrders
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(AddReview);
