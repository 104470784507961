import React from 'react';
import { useSelector } from 'react-redux';
import { stringify } from 'query-string';

import { cn } from 'helpers/classnames';
import type { AppState } from 'types/app';
import type { SocialMediaProfile, SocialMediaProfileRequestBody } from 'types/influencer';
import { ProfileType, ProfileValidationStatus } from 'types/influencer';
import { SocialMediaAppDetails } from 'constants/SocialMediaAppDetails';
import { INFLUENCER_ADD_PROFILE_CALLBACK_PAGE } from 'constants/influencerPages';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/influencer/socialMediaCard.scss';

interface SocialMediaCardProps {
  details: SocialMediaProfile | undefined;
  profileType: ProfileType;
  handleAddProfile: (requestBody: SocialMediaProfileRequestBody) => void;
  imgSrc: string;
  clientId: Object;
}

export const SocialMediaCard = (props: SocialMediaCardProps) => {
  const { testId } = useMartyContext();
  const { details, profileType, imgSrc, clientId } = props || {};

  const { host, protocol } = useSelector((state: AppState) => state.url);
  let redirectUri = protocol + '://' + host;

  const getDate = (epochDate: number) =>
    new Date(epochDate).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });

  const makeEnrolledProfileDetails = () => {
    if (details) {
      const { profileName, validationStatus, updatedAt, canReapply } = details;
      let statusStyle;
      let statusText;
      let statusReason = '';
      switch (validationStatus) {
        case ProfileValidationStatus.VALIDATED:
          statusStyle = css.qualified;
          statusText = 'Approved';
          break;
        case ProfileValidationStatus.PENDING:
          statusStyle = css.pending;
          statusText = 'Pending Verification';
          break;
        case ProfileValidationStatus.DECLINED:
          statusStyle = css.declined;
          statusText = 'Does not meet the eligibility requirements at this time';
          if (!canReapply) {
            statusReason =
              'You can apply again 30 days from the day you submitted the application.' +
              ' Once you’ve increased your level of influence in your social media account(s), you can get approved.';
          }

          break;
      }
      return (
        <div className={css.enrolledProfileDetails}>
          <span className={css.profileName}>{profileName}</span>
          <span className={cn(css.status, statusStyle)}>{statusText}</span>
          <time className={css.submittedOn}>Submitted on {getDate(updatedAt)}</time>
          <span>{statusReason}</span>
        </div>
      );
    } else {
      return;
    }
  };

  const makeSocialMediaApplyBtn = () => {
    const smDetails = SocialMediaAppDetails[profileType];

    if (profileType === ProfileType.TIKTOK) {
      redirectUri = redirectUri.replace(/^https:\/\/www./, 'https://');
    }

    const label = profileType === ProfileType.YOUTUBE ? 'YouTube' : profileType;

    const params = {
      ...smDetails.params,
      redirect_uri: `${redirectUri}${INFLUENCER_ADD_PROFILE_CALLBACK_PAGE}`,
      ...clientId
    };
    return (
      <a
        className={cn(css.profileDetails, {
          [css.disableApply]: details && !details.canReapply
        })}
        href={`${smDetails.oAuthUrl}?${stringify(params)}`}
        aria-label={smDetails.ariaLabel}
      >
        <div className={css.profileType}>
          <img data-test-id={testId('influencerMediaCardLink')} src={imgSrc} alt={`${profileType} Logo`} />
          <h3 data-test-id={testId('influencerMediaCardLabel')}>{label}</h3>
        </div>
      </a>
    );
  };

  return (
    <article className={css.card}>
      {makeSocialMediaApplyBtn()}
      {makeEnrolledProfileDetails()}
    </article>
  );
};

export default SocialMediaCard;
