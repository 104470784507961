import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  handleAddSocialProfile,
  handleGetInfluencerAppsConfig,
  handleGetInfluencerStatus,
  redirectBasedOnInfluencerDetails
} from 'actions/influencer/influencer';
import { PageLoader } from 'components/Loader';
import SocialMediaCardList from 'components/influencer/SocialMediaCardList';
import { InfluencerStatus } from 'types/influencer';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/influencer/influencerEligibility.scss';

export const InfluencerEligibility = props => {
  const { testId } = useMartyContext();
  const {
    handleGetInfluencerStatus,
    redirectBasedOnInfluencerDetails,
    handleAddSocialProfile,
    handleGetInfluencerAppsConfig,
    influencer,
    influencer: { isLoading, socialMediaProfiles, appConfig },
    slotDetails: { heading, copy }
  } = props;

  useEffect(() => {
    handleGetInfluencerAppsConfig();
    handleGetInfluencerStatus();
  }, [handleGetInfluencerStatus, redirectBasedOnInfluencerDetails, handleGetInfluencerAppsConfig]);

  if (appConfig && influencer?.status === InfluencerStatus.PENDING && Object.keys(socialMediaProfiles).length === 0 && !isLoading) {
    return (
      <div className={css.eligibility}>
        <h2 data-test-id={testId('influencerEligibilityHeading')}>{heading}</h2>
        <p data-test-id={testId('influencerEligibilityCopy')}>{copy}</p>
        <SocialMediaCardList influencer={influencer} handleAddProfile={handleAddSocialProfile} />
      </div>
    );
  } else {
    return <PageLoader centered={true} />;
  }
};
const mapStateToProps = state => {
  const { influencer } = state;
  return { influencer };
};
const mapDispatchToProps = {
  handleGetInfluencerAppsConfig,
  handleGetInfluencerStatus,
  handleAddSocialProfile,
  redirectBasedOnInfluencerDetails
};
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(InfluencerEligibility);
