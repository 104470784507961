import type { ChangeEventHandler } from 'react';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { cn } from 'helpers/classnames';
import { AriaLiveTee } from 'components/common/AriaLive';

import css from 'styles/components/reviews/addReviewRating.scss';

const ratingNames = ['Very Poor', 'Poor', 'Good', 'Very Good', 'Outstanding'];
const ratingCategories: { name: string; id: RatingIds }[] = [
  { name: 'Overall', id: 'overall' },
  { name: 'Comfort', id: 'comfort' },
  { name: 'Style', id: 'look' }
];

type RatingIds = 'overall' | 'comfort' | 'look';
type RatingKeys = 'overallRating' | 'comfortRating' | 'lookRating';

type Ratings = {
  [index in RatingKeys]: string;
};

interface AddReviewRatingProps {
  ratings: Ratings;
  errors: Record<string, boolean>;
  onRatingChange: ChangeEventHandler<HTMLInputElement>;
}

const AddReviewRating = ({ ratings, errors = {}, onRatingChange }: AddReviewRatingProps) => {
  const hasUnsetRatings = Object.values(ratings).some(value => value === '0'); // check if there are any 'zero' ratings
  return (
    <div className={css.wrapper}>
      {!!Object.keys(errors).length && hasUnsetRatings && (
        <AriaLiveTee>
          <div className={css.errorBox}>Please select ratings to submit your review</div>
        </AriaLiveTee>
      )}
      {ratingCategories.map(({ name, id }) => {
        const isSelected = (value: number) => {
          const key = `${id}Rating` as keyof Ratings;
          return parseInt(ratings[key], 10) === value;
        };
        return (
          <div key={id} className={css.ratingContainer}>
            <p
              id={id}
              className={cn(css.ratingLabel, {
                [css.error]: errors[`${id}Rating`]
              })}
            >
              {name}
            </p>
            <div className={css.ratingValue} role="radiogroup" aria-labelledby={id}>
              <input readOnly type="radio" name={id} value={0} data-rating="0Rating" checked={false} aria-label={`No rating for ${id}`} />
              {ratingNames.map((ratingName, index) => {
                const starValue = index + 1;
                return (
                  <Fragment key={starValue}>
                    <input
                      id={`${id}-star${starValue}`}
                      type="radio"
                      data-rating={`${id}Rating`}
                      value={starValue}
                      name={id}
                      checked={isSelected(starValue)}
                      onChange={onRatingChange}
                      required
                    />
                    <label htmlFor={`${id}-star${starValue}`} title={ratingName}>
                      Rate {id} {starValue} out of 5 stars
                    </label>
                  </Fragment>
                );
              })}
              <span className={css.starSprite} aria-hidden={true} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

AddReviewRating.propTypes = {
  ratings: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onRatingChange: PropTypes.func.isRequired
};

export default AddReviewRating;
