import React from 'react';
import { connect } from 'react-redux';

import { cn } from 'helpers/classnames';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/influencer/influencerLandingPageHeading.scss';

export const InfluencerLandingPageHeading = props => {
  const { testId } = useMartyContext();
  const xPositionClassMap = {
    left: css.left,
    right: css.right,
    center: css.center
  };
  const {
    isExistingInfluencer,
    slotDetails: {
      pageHeading = '',
      pageHeadingExisting = '',
      pageTitle = '',
      pageTitleExisting = '',
      pageTitleMobile = '',
      description = [],
      mobilesrc,
      src,
      alt,
      position = 'center'
    }
  } = props;

  const positionCss = xPositionClassMap[position];

  const descriptionSeparate = description.map((par, index) => <p key={`${index} - ${par}`}>{par}</p>);

  return (
    <article className={css.headingContainer}>
      {src && (
        <picture>
          <source media="(max-width: 650px)" srcSet={mobilesrc} />
          <source media="(min-width: 651px) and (max-width: 1024px)" srcSet={src} />
          <img src={src} alt={alt} className={css.bannerImage} />
        </picture>
      )}
      {isExistingInfluencer && !!pageHeadingExisting ? (
        <div className={cn(positionCss, css.bannerPageHeading, css.existingOverride)}>
          <h1>{pageHeadingExisting}</h1>
          <p className={css.copy}>{pageTitleExisting}</p>
        </div>
      ) : (
        <div className={cn(positionCss, src ? css.bannerPageHeading : css.pageHeading)}>
          <h1 data-test-id={testId('influencerHomePageHeading')}>{pageHeading}</h1>
          <p data-test-id={testId('influencerHomePageTitle')} className={css.copy}>
            {pageTitle}
          </p>
          <p className={css.copyMobile}>{pageTitleMobile}</p>
          <div data-test-id={testId('influencerHomePageDescription')}>{descriptionSeparate}</div>
        </div>
      )}
    </article>
  );
};
const mapStateToProps = state => {
  const {
    influencer: { name, profileHandle }
  } = state;
  const isExistingInfluencer = !!name && !profileHandle;
  return { isExistingInfluencer };
};
const connectedInfluencerLandingPageHeading = connect(mapStateToProps)(InfluencerLandingPageHeading);

export default withErrorBoundary('InfluencerLandingPageHeading', connectedInfluencerLandingPageHeading);
