import PropTypes from 'prop-types';

import css from 'styles/components/checkout/typ/voiceOfCustomer.scss';

const VoiceOfCustomer = (
  undefined,
  {
    testId,
    marketplace: {
      checkout: { vocImage, vocText, vocUrl }
    }
  }
) => (
  <div className={css.container}>
    <div>
      <img alt="Your Voice Logo" src={vocImage} />
    </div>
    <div>
      {vocText}
      <div>
        <a className={css.vocBtn} data-test-id={testId('takeSurvey')} href={vocUrl} target="_blank" rel="noopener noreferrer">
          Take the Survey
        </a>
      </div>
    </div>
  </div>
);

VoiceOfCustomer.contextTypes = {
  testId: PropTypes.func,
  marketplace: PropTypes.object
};

export default VoiceOfCustomer;
