import { Component } from 'react';
import { connect } from 'react-redux';

import AddReview from 'containers/AddReview';
import { PageLoader } from 'components/Loader';
import { loadWriteReviewPage } from 'actions/addReview';
import { getWriteReviewReturnUrl } from 'helpers/ReviewUtils';

function isProductDataLoaded(product, params) {
  return params.asin && product.requestedAsin === params.asin;
}

// this is _very_ similar to AsinProductDetail, but is split out so that for PDP webpack doesn't bundle in extra AddReview code
// there is probably a webpack config to change this behavior, but I was unable to decipher it.
export class AsinAddReview extends Component {
  static fetchDataOnServer(store, location, params) {
    return store.dispatch(loadWriteReviewPage(params, { firePixel: false, errorOnOos: false }));
  }

  componentDidMount() {
    const {
      loadWriteReviewPage,
      product,
      match: { params }
    } = this.props;
    if (!isProductDataLoaded(product, params)) {
      loadWriteReviewPage(params, { firePixel: false });
    }
  }

  render() {
    const {
      product,
      match: { params }
    } = this.props;
    if (isProductDataLoaded(product, params)) {
      const {
        colorId,
        detail: { productId }
      } = product;
      const pdpParams = { productId, colorId };
      const location = {
        pathname: getWriteReviewReturnUrl(productId, colorId)
      };
      return <AddReview inParams={pdpParams} location={location} />;
    } else {
      return <PageLoader />;
    }
  }
}

const mapStateToProps = state => ({
  product: state.product
});

export default connect(mapStateToProps, { loadWriteReviewPage })(AsinAddReview);
