import React, { useEffect } from 'react';

import { cn } from 'helpers/classnames';
import { InfluencerHeader } from 'components/influencer/InfluencerHeader';
import InfluencerFooter from 'components/influencer/InfluencerFooter';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/containers/influencer/influencerHeaderFooterWrapper.scss';

interface OwnProps {
  setHeaderFooterVisibility: (flag: boolean) => void;
  isLandingPage: boolean;
  slotData?: any;
  children: JSX.Element[] | JSX.Element;
}

export const InfluencerHeaderFooterWrapper = (props: OwnProps) => {
  const { testId } = useMartyContext();
  const { setHeaderFooterVisibility } = props;
  const { isLandingPage, slotData } = props;

  useEffect(() => {
    setHeaderFooterVisibility(false);
    return () => {
      setHeaderFooterVisibility(true);
    };
  }, [setHeaderFooterVisibility]);
  return (
    <div
      className={cn(css.page, isLandingPage ? [css.landingBackground] : [css.nonLandingBackground])}
      data-test-id={testId('influencerHeaderFooterWrapper')}
    >
      <InfluencerHeader isLandingPage={isLandingPage} slotData={slotData} />
      <div>{props.children}</div>
      <InfluencerFooter slotData={slotData} />
    </div>
  );
};

export default withErrorBoundary('InfluencerHeaderFooterWrapper', InfluencerHeaderFooterWrapper);
